import classes from "./Card.module.scss";

const Header = ({ children }) => {
  return <header className={classes.header}>{children}</header>;
};

const Card = ({ children }) => {
  return <div className={classes.card}>{children}</div>;
};

Card.Header = Header;
export default Card;
