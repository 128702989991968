import { Link } from "react-router-dom";

import { Container } from "components/core";
import classes from "./NavLink.module.scss";

const NavLink = (props) => {
  const { path, label } = props;
  return (
    <Container>
      <Link to={path} className={classes.navLink}>
        {label} ››
      </Link>
    </Container>
  );
};

export default NavLink;
