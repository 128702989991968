import classes from "./Paragraph.module.scss";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const Paragraph = (props) => {
  const { content = "", altFont = false, alignText = "left" } = props;
  const controls = useAnimation();
  const { ref, inView } = useInView();

  const variants = {
    hidden: {
      y: 100,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 2,
      },
    },
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    // if (!inView) {
    //   controls.start("hidden");
    // }
  }, [controls, inView]);

  return (
    <motion.p
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
      className={`${classes.paragraph} ${altFont && classes.altFont} ${
        alignText && classes[alignText]
      }`}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default Paragraph;
