import { useEffect, useState } from "react";

import classes from "./MediaGallery.module.scss";

const MediaGallery = ({ content = [], name, handleChange }) => {
  const [items, setItems] = useState(content || []);

  const handleSave = (e) => {
    e.preventDefault();
    const arr = [...e.target.files];
    handleChange(arr, name);
  };

  useEffect(() => {
    if (content.length) {
      setItems(
        content.map(
          (file) =>
            `${process.env.REACT_APP_API_URL.replace(
              "/api",
              ""
            )}/${file.replace("media/", "")}`
        )
      );
    }
  }, [content]);

  return (
    <div className={classes.mediaGallery}>
      <input type="file" name="gallery" onChange={handleSave} multiple />
      <div className={classes.gallery}>
        {items.length > 0 &&
          items.map((item, index) => {
            return (
              <div key={`media-gallery-item-${index}`} className={classes.item}>
                <img
                  src={
                    item.length
                      ? typeof (item === "string")
                        ? item
                        : URL.createObjectURL(item)
                      : "https://via.placeholder.com/300"
                  }
                  alt=""
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MediaGallery;
