import styles from "./Container.module.css";

const Container = ({ children, fullSize, small }) => {
  return (
    <div
      className={`${styles.container} ${fullSize ? styles.containerFull : ""} ${
        small ? styles.containerSmall : ""
      }`}
    >
      {children}
    </div>
  );
};

export default Container;
