import axios from "axios";

const getEntityTranslation = async (id, lang) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/translations/entities/${id}`,
    {
      params: {
        lang,
      },
    }
  );
  if (data.success) return data.data;
};

const getPageTranslation = async (slug, lang) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/translations/pages/${slug}`,
    {
      params: {
        lang,
      },
    }
  );
  if (data.success) return data.data;
};

const updateOrCreateById = async (id, translations) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/translations/entities/${id}`,
    {
      translations,
    }
  );
  if (data.success) return data.data;
};

const updateOrCreatePage = async (slug, translations) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/translations/pages/${slug}`,
    {
      translations,
    }
  );
  if (data.success) return data.data;
};

const api = {
  getEntityTranslation,
  getPageTranslation,
  updateOrCreateById,
  updateOrCreatePage,
};

export default api;
