import axios from "axios";
import { AdminContext } from "contexts/admin.context";
import { CmsUserContext } from "contexts/cmsUserContext";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classes from "./AdminSidebar.module.scss";

const AdminSidebar = () => {
  const { adminBasePath, theme } = useContext(AdminContext);
  const { user } = useContext(CmsUserContext);
  const [showSidebar, setSidebarVisible] = useState(true);
  const [collections, setCollections] = useState([]);
  const [pages, setPages] = useState([]);
  const [navigation, setNavigation] = useState({
    controlPanel: true,
    pages: false,
    collections: false,
    analytics: false,
  });

  const { t } = useTranslation();

  const fetchCollections = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/collections/list`)
      .then((res) => res.data)
      .then(({ data }) => setCollections(data));
  };
  const fetchPages = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/pages/list`)
      .then((res) => res.data)
      .then(({ data }) => setPages(data));
  };

  useEffect(() => {
    setSidebarVisible(true);
    fetchCollections();
    fetchPages();
  }, []);

  const renderDashboard = () => {
    return (
      <div className={classes.menuBlock}>
        <h3
          className={`${navigation.controlPanel ? classes.open : ""}`}
          onClick={() =>
            setNavigation((prev) => ({
              ...prev,
              controlPanel: !navigation.controlPanel,
            }))
          }
        >
          {t(`backend.sidebar.controlPanel`)}
          <span>›</span>
        </h3>
        <ul className={`${navigation.controlPanel ? classes.open : ""}`}>
          <li>
            <Link to={`${adminBasePath}`}>
              {t(`backend.sidebar.dashboard`)}
            </Link>
          </li>
          {user.role === "SUPERADMIN" && (
            <>
              <li>
                <Link to={`${adminBasePath}/users`}>
                  {t(`backend.sidebar.users`)}
                </Link>
              </li>
              {/* <li>
                <Link to={`${adminBasePath}/analytics`}>
                  {t(`backend.sidebar.analytics`)}
                </Link>
              </li> */}
              {/* <li>
                <Link to={`${adminBasePath}/navigation`}>
                  {t(`backend.sidebar.navigation`)}
                </Link>
              </li> */}
              <li>
                <Link to={`${adminBasePath}/settings`}>
                  {t(`backend.sidebar.settings`)}
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    );
  };

  const renderPages = () => {
    if (user.role === "SUPERADMIN" || user.role === "ADMIN")
      return (
        <div className={classes.menuBlock}>
          <h3
            className={`${navigation.pages ? classes.open : ""}`}
            onClick={() =>
              setNavigation((prev) => ({
                ...prev,
                pages: !navigation.pages,
              }))
            }
          >
            {t(`backend.sidebar.pages`)}
            <span>›</span>
          </h3>
          <ul className={`${navigation.pages ? classes.open : ""}`}>
            <li>
              <Link to={`${adminBasePath}/pages/create`}>
                {t(`backend.sidebar.createPage`)}
              </Link>
            </li>
            {pages.map(({ id, label, slug }) => (
              <li key={`admin-panel-pages-${id}-${slug}`}>
                <Link to={`${adminBasePath}/pages/edit/${slug}`}>{label}</Link>
              </li>
            ))}
          </ul>
        </div>
      );
    return null;
  };

  const renderCollections = () => {
    return (
      <div className={classes.menuBlock}>
        <h3
          className={`${navigation.collections ? classes.open : ""}`}
          onClick={() =>
            setNavigation((prev) => ({
              ...prev,
              collections: !navigation.collections,
            }))
          }
        >
          {t(`backend.sidebar.collections`)}
          <span>›</span>
        </h3>
        <ul className={`${navigation.collections ? classes.open : ""}`}>
          {collections.map(({ id, label, slug }) => (
            <li key={`admin-panel-collection-${id}-${slug}`}>
              <Link to={`${adminBasePath}/collections/${slug}`}>{label}</Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <aside
      className={`${classes.adminSidebar} ${
        theme === "dark" ? classes.dark : ""
      }`}
    >
      <nav>
        {showSidebar && renderDashboard()}
        {pages.length > 0 && renderPages()}
        {collections.length > 0 && renderCollections()}
      </nav>
    </aside>
  );
};

export default AdminSidebar;
