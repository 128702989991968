import React from "react";
import ReactDOM from "react-dom";

import { AdminProvider } from "contexts/admin.context";
import { CmsUserProvider } from "contexts/cmsUserContext";
import { LanguageProvider } from "contexts/languageContext";

import App from "./App";
import "./index.css";

import en from "i18n/en.json";
import it from "i18n/it.json";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    it: {
      translation: it,
    },
  },
  lng: "it",
  fallbackLng: "it",
  interpolation: {
    escapeValue: false,
  },
});

ReactDOM.render(
  <AdminProvider>
    <CmsUserProvider>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </CmsUserProvider>
  </AdminProvider>,
  document.getElementById("root")
);
