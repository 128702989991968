import { Fragment, useEffect, useState } from "react";

import { DialogBox } from "components/cms";
import CropImage from "components/cms/core/CropImage";
import classes from "./ImageUpload.module.scss";

const ImageUpload = ({ content, required, name, handleChange }) => {
  const [src, setSrc] = useState();
  const [thumbnail, setThumbnail] = useState(
    "https://via.placeholder.com/1920x768"
  );
  const [file, setFile] = useState();
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (content)
      setThumbnail(
        `${process.env.REACT_APP_API_URL.replace("/api", "")}/${content.replace(
          "media/",
          ""
        )}`
      );
  }, [content]);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSrc(reader.result));
      reader.readAsDataURL(e.target.files[0]);

      setFile(e.target.files[0]);
      handleChange(e.target.files[0], name);
      setThumbnail(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    handleChange(file, name);
    setModalOpen(false);
  };

  const handleLoad = (e) => {
    setThumbnail(e);
  };

  return (
    <Fragment>
      <div className={classes.imageUpload}>
        {thumbnail && (
          <div className={classes.thumbnail}>
            <img src={thumbnail} alt="" />
            <button
              onClick={(e) => {
                e.preventDefault();
                setModalOpen(true);
              }}
            >
              Modifica anteprima
            </button>
          </div>
        )}
        <input
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          required={required}
        />
      </div>
      {src && isModalOpen && (
        <DialogBox closeDialog={() => setModalOpen(false)}>
          <div className={classes.dialogContent}>
            <h3>Modifica anteprima dell'immagine</h3>
            <CropImage src={src} onComplete={handleLoad} />
            <div className={classes.userActions}>
              <button onClick={handleSave}>Salva</button>
              <button onClick={handleSave}>Annulla</button>
            </div>
          </div>
        </DialogBox>
      )}
    </Fragment>
  );
};

export default ImageUpload;
