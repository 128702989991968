import { PencilIcon, TrashIcon } from "assets/icons";
import { useEffect, useState } from "react";

const dictionary = {
  blockTypes: {
    Paragraph: "Testo",
    Heading: "Titolo",
    Grid: "Galleria immagini",
    SplashImage: "Immagine a tutto schermo",
    NavLink: "Collegamento a pagina interna",
    ExtLink: "Collegamento a pagina esterna",
    ImageText: "Immagine e testo",
  },
};

const PreviewItem = ({
  block,
  index,
  classes,
  openModal,
  deleteBlock,
  totalBlocks,
  changeBlockOrder,
  setSelectedBlock,
}) => {
  const handleOpen = (e) => {
    e.preventDefault();
    openModal(true);
    setSelectedBlock(index);
  };

  const [src, setSrc] = useState("https://via.placeholder.com/1920x768");

  useEffect(() => {
    if (block.props.src && block.blockType === "SplashImage")
      setSrc(
        `${process.env.REACT_APP_API_URL.replace(
          "/api",
          ""
        )}/${block.props.src.replace("media/", "")}`
      );
    if (block.props.image && block.blockType === "ImageText")
      setSrc(
        `${process.env.REACT_APP_API_URL.replace(
          "/api",
          ""
        )}/${block.props.image.replace("media/", "")}`
      );
    //eslint-disable-next-line
  }, []);

  return (
    <div className={classes.previewItem}>
      <span className={classes[block.blockType]}>
        {dictionary.blockTypes[block.blockType]}
      </span>

      {block.blockType === "NavLink" && (
        <div>
          <p>
            Collegamento a: <code>{block.props.path}</code>
          </p>
        </div>
      )}

      {block.blockType === "ExtLink" && (
        <div>
          <p>
            Collegamento esterno a: <code>{block.props.path}</code>
          </p>
        </div>
      )}

      {block.blockType === "ImageText" && (
        <div className={classes.previewImgText}>
          <img src={src} alt={block.props.altText || ""} />
          <div dangerouslySetInnerHTML={{ __html: block.props.text }} />
        </div>
      )}

      {block.blockType === "Grid" && (
        <div className={classes.previewGallery}>
          {block.props.items &&
            block.props.items.map((item, index) => {
              return (
                <div key={`block-grid-${index}`}>
                  <img
                    src={`${process.env.REACT_APP_API_URL.replace(
                      "/api",
                      ""
                    )}/${item.replace("media/", "")}`}
                    alt=""
                  />
                </div>
              );
            })}
        </div>
      )}

      {block.blockType === "SplashImage" && (
        <div className={classes.previewSplashImage}>
          <img src={src} alt="" />
        </div>
      )}

      {block.blockType === "Paragraph" && (
        <p
          dangerouslySetInnerHTML={{
            __html: block.props.content,
          }}
        />
      )}

      {block.blockType === "Heading" && <p>{block.props.content}</p>}

      <select
        name={`block-${index}-order-dropdown`}
        value={index}
        onChange={(e) => changeBlockOrder(e, index)}
      >
        {[...Array(totalBlocks)].map((_, index) => (
          <option key={index} value={index}>
            {index}
          </option>
        ))}
      </select>
      <button onClick={handleOpen}>
        <PencilIcon />
      </button>
      <button onClick={() => deleteBlock(index)}>
        <TrashIcon />
      </button>
    </div>
  );
};

export default PreviewItem;
