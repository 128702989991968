import classes from "./Heading.module.scss";

const Heading = (props) => {
  const { customClasses = [], weight = 1, content = "" } = props;
  switch (Number(weight)) {
    case 1:
      return (
        <h1
          className={`${classes.heading} ${customClasses.map((el, idx) => {
            if (idx < customClasses.length - 1) return el + " ";
            return el;
          })}`}
        >
          {content}
        </h1>
      );
    case 2:
      return (
        <h2
          className={`${classes.heading} ${customClasses.map((el, idx) => {
            if (idx < customClasses.length - 1) return el + " ";
            return el;
          })}`}
        >
          {content}
        </h2>
      );
    case 3:
      return (
        <h3
          className={`${classes.heading} ${customClasses.map((el, idx) => {
            if (idx < customClasses.length - 1) return el + " ";
            return el;
          })}`}
        >
          {content}
        </h3>
      );
    case 4:
      return (
        <h4
          className={`${classes.heading} ${customClasses.map((el, idx) => {
            if (idx < customClasses.length - 1) return el + " ";
            return el;
          })}`}
        >
          {content}
        </h4>
      );
    case 5:
      return (
        <h5
          className={`${classes.heading} ${customClasses.map((el, idx) => {
            if (idx < customClasses.length - 1) return el + " ";
            return el;
          })}`}
        >
          {content}
        </h5>
      );
    case 6:
      return (
        <h6
          className={`${classes.paragraph} ${customClasses.map((el, idx) => {
            if (idx < customClasses.length - 1) return el + " ";
            return el;
          })}`}
        >
          {content}
        </h6>
      );
    default:
      return (
        <h1
          className={`${classes.paragraph} ${customClasses.map((el, idx) => {
            if (idx < customClasses.length - 1) return el + " ";
            return el;
          })}`}
        >
          {content}
        </h1>
      );
  }
};

export default Heading;
