import classes from "./Pagination.module.scss";

const Pagination = (props) => {
  const { page, setPage, totalPages } = props;
  if (totalPages === 1) return null;

  return (
    <div className={classes.pagination}>
      <button disabled={page <= 1}>Prev</button>
      {Array([totalPages]).map((_, i) => {
        return (
          <button
            key={`pagination-collection-btn-${i}`}
            className={`${page === i ? classes.currentPage : ""}`}
            onClick={() => setPage(i)}
          >
            {i + 1}
          </button>
        );
      })}
      <button disabled={page + 1 >= totalPages}>Next</button>
    </div>
  );
};

export default Pagination;
