import { useState, useEffect } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const BlockParagraph = (props) => {
  const {
    block,
    allBlocks,
    handleBlock,
    openParagraphModal,
    setSelectedBlock,
    classes,
  } = props;

  const selectedIndex = block ?? -1;
  const selectedBlock = block >= 0 ? allBlocks[block] : {};

  const [content, setContent] = useState();
  const [wrapperSize, setWrapperSize] = useState(
    selectedBlock?.wrapperSize || "small"
  );
  const [altFont, setAltFont] = useState(
    selectedBlock?.props?.altFont || false
  );
  const [alignText, setAlignText] = useState(
    selectedBlock?.props?.alignText || "left"
  );

  useEffect(() => {
    if (selectedBlock?.props?.content.length) {
      const contentBlock = htmlToDraft(selectedBlock?.props?.content);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setContent(editorState);
    } else {
      setContent(EditorState.createEmpty());
    }
  }, [selectedBlock?.props?.content]);

  const onEditorStateChange = (editorState) => {
    setContent(editorState);
  };

  const handleSave = () => {
    handleBlock(selectedIndex, {
      blockType: "Paragraph",
      wrapperSize,
      props: {
        content: draftToHtml(convertToRaw(content.getCurrentContent())),
        altFont,
        alignText,
      },
    });
    resetContent();
  };

  const resetContent = () => {
    setContent("");
    setWrapperSize("");
    setAltFont(false);
    openParagraphModal(false);
    setSelectedBlock(null);
  };

  return (
    <div className={classes.dialog}>
      <h3>Aggiungi paragrafo di testo</h3>
      <fieldset>
        <label htmlFor="content">Contenuto</label>

        <Editor
          editorState={content}
          onEditorStateChange={onEditorStateChange}
          wrapperClassName={classes.wrapper}
          editorClassName={classes.editor}
          toolbarClassName={classes.toolbar}
          toolbar={{
            options: ["inline", "list", "textAlign", "history", "link"],
            inline: {
              inDropdown: false,
              options: ["bold", "italic", "underline"],
            },
            list: {
              inDropdown: false,
              options: ["unordered", "ordered"],
            },
            textAlign: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["left", "center", "right", "justify"],
            },
            link: { inDropdown: false },
            history: { inDropdown: false },
          }}
        />
      </fieldset>
      <fieldset>
        <label htmlFor="wrapper">Dimensione contenitore</label>
        <select
          name="wrapperSize"
          onChange={(e) => setWrapperSize(e.target.value)}
          defaultValue={wrapperSize}
        >
          <option value="small">Stretta</option>
          <option value="large">Larga</option>
          <option value="none">Nessuna</option>
        </select>
      </fieldset>
      <fieldset>
        <label htmlFor="alignText">Allineamento testo</label>
        <select
          name="alignText"
          onChange={(e) => setAlignText(e.target.value)}
          defaultValue={alignText}
        >
          <option value="left">Allinea a sinistra</option>
          <option value="center">Allinea in centro</option>
          <option value="right">Allinea a destra</option>
        </select>
      </fieldset>
      <fieldset>
        <label htmlFor="altFont">Font alternativo</label>
        <input
          type="checkbox"
          name="altFont"
          onChange={(e) => setAltFont(e.target.checked)}
          checked={altFont}
        />
      </fieldset>
      <div className={classes.dialogButtons}>
        <button onClick={resetContent}>Annulla</button>
        <button onClick={handleSave}>Salva</button>
      </div>
    </div>
  );
};

export default BlockParagraph;
