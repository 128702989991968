import { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Api from "api";
import { AdminContext } from "contexts/admin.context";

import AdminRoutes from "routes/AdminRoutes";
import Login from "screens/admin/Login/Login";

import PublicRoutes from "routes/PublicRoutes";
import ScrollToTop from "components/core/ScrollToTop";
import { NotFound } from "screens";

const App = () => {
  const [collections, setCollections] = useState([]);
  const [pages, setPages] = useState([]);
  const { adminBasePath } = useContext(AdminContext);

  useEffect(() => {
    const fetchData = async () => {
      const collectionList = await Api.collections.getList();
      const pageList = await Api.pages.getList();
      setCollections(collectionList);
      setPages(pageList);
    };

    fetchData();

    //eslint-disable-next-line
  }, []);

  let basePath = "/admin";
  if (adminBasePath) basePath = adminBasePath;

  const adminPaths = [
    `${basePath}`,
    `${basePath}/settings`,
    `${basePath}/navigation`,
    `${basePath}/analytics`,
    `${basePath}/users`,
    ...collections.map((el) => `${basePath}/collections/${el.slug}`),
    ...collections.map((el) => `${basePath}/collections/${el.slug}/edit/:id`),
    `${basePath}/pages/create`,
    ...pages.map((el) => `${basePath}/pages/edit/${el.slug}`),
  ];

  const publicPaths = [
    "/journal",
    "/journal/:slug",
    "/case-history",
    "/case-history/:slug",
    ...pages.map((page) => {
      if (page.slug === "homepage") return "/";
      return `/${page.slug}`;
    }),
  ];

  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route exact path={publicPaths}>
            <PublicRoutes pages={pages} />
          </Route>
          <Route exact path={adminPaths}>
            <AdminRoutes collections={collections} pages={pages} />
          </Route>
          <Route exact path={`${basePath}/login`} component={Login} />
          <Route exact path="*" component={NotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default App;
