import axios from "axios";
import { useTranslation } from "react-i18next";
import { InfoTooltip } from "components/cms";
import { Page } from "components/cms/core";
import { AdminContext } from "contexts/admin.context";
import { CmsUserContext } from "contexts/cmsUserContext";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./Dashboard.module.scss";

const initialOpts = {
  showCollections: true,
  showPages: true,
  showAnalytics: false,
  showUsers: false,
  showOrders: false,
};

const tooltips = {
  pages: {
    dialogBox: "Pages",
  },
};

const Dashboard = () => {
  const { t } = useTranslation();
  const [options, setOptions] = useState({});
  const [collections, setCollections] = useState([]);
  const [pages, setPages] = useState([]);
  const { user } = useContext(CmsUserContext);

  const { adminBasePath } = useContext(AdminContext);

  const fetchCollections = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/collections/list`)
      .then((res) => res.data)
      .then(({ data }) => setCollections(data));
  };
  const fetchPages = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/pages/list`)
      .then((res) => res.data)
      .then(({ data }) => setPages(data));
  };

  useEffect(() => {
    setOptions(initialOpts);
    fetchCollections();
    fetchPages();

    if (user.role !== "SUPERADMIN" && user.role !== "ADMIN") {
      setOptions((prev) => ({ ...prev, showPages: false }));
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Page title={t("backend.sidebar.dashboard")}>
      <div className={classes.dashboardTables}>
        {options.showPages && (
          <div className={classes.adminTable}>
            <h3>
              Pagine
              <InfoTooltip {...tooltips.pages} />
            </h3>
            {pages.map((page) => {
              return (
                <div
                  key={`admin-dashboard-pages-${page.id}`}
                  className={classes.collectionRow}
                >
                  <Link to={`${adminBasePath}/pages/edit/${page.slug}`}>
                    <p>
                      {page.label}
                      <span
                        style={{
                          color: page.isPublished
                            ? "hsl(203.3, 32.8%, 62.2%)"
                            : "hsl(356.5, 95.6%, 35.5%)",
                          fontWeight: 600,
                        }}
                      >
                        {page.isPublished ? "Pubblicata" : "Non pubblicata"}
                      </span>
                    </p>
                  </Link>
                </div>
              );
            })}
          </div>
        )}
        {options?.showCollections && (
          <div className={classes.adminTable}>
            <h3>Collezioni</h3>
            {collections.map((collection, index) => {
              return (
                <div
                  key={`admin-dashboard-collections-${collection.id}`}
                  className={classes.collectionRow}
                >
                  <Link to={`${adminBasePath}/collections/${collection.slug}`}>
                    <p>
                      {collection.label}
                      <span>
                        Elementi totali: {collections[index].totalItems}
                      </span>
                    </p>
                  </Link>
                </div>
              );
            })}
          </div>
        )}
        {options.showAnalytics && (
          <div className={classes.adminTable}>
            <h3>Analytics</h3>
          </div>
        )}
        {options.showUsers && (
          <div className={classes.adminTable}>
            <h3>Test</h3>
          </div>
        )}
        {options.showOrders && (
          <div className={classes.adminTable}>
            <h3>Test</h3>
          </div>
        )}
      </div>
    </Page>
  );
};

export default Dashboard;
