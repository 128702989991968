const InputText = (props) => {
  const {
    label = "",
    name = "",
    value = "",
    handleChange = () => {},
    required = true,
  } = props;

  return (
    <fieldset>
      <label htmlFor={label.toLowerCase()}>{label}</label>
      <input
        type="text"
        name={name}
        aria-label={label.toLowerCase()}
        value={value}
        onChange={handleChange}
        disabled={name === "slug"}
        required={required}
      />
    </fieldset>
  );
};

export default InputText;
