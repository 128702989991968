import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import classes from "./AdminHeader.module.scss";
import { CmsUserContext } from "contexts/cmsUserContext";
import { AdminContext } from "contexts/admin.context";
import Api from "api";

import logo from "assets/img/logo-horiz-alt.svg";
import { useTranslation } from "react-i18next";

const AdminHeader = () => {
  const { user, setUser } = useContext(CmsUserContext);
  const { adminBasePath, theme } = useContext(AdminContext);
  const history = useHistory();
  const { t } = useTranslation();

  const handleLogout = async () => {
    await Api.admin.auth.logout();
    setUser(null);
    history.push(`${adminBasePath}/login`);
  };

  return (
    <header
      className={`${classes.adminHeader} ${
        theme === "dark" ? classes.dark : ""
      }`}
    >
      {/* BRAND LOGO */}
      <div className={classes.brandLogo}>
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className={classes.userActions}>
        <div tabIndex={1}>
          {t(`backend.core.welcome`)}, {user.firstName}
          <ul>
            <hr />
            <li>
              <Link to="/">{t(`backend.core.backToSite`)}</Link>
              <button onClick={() => handleLogout()}>
                {t(`backend.core.logOut`)}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
