import { useEffect, useState } from "react";
import { useParams } from "react-router";

import Api from "api";

import {
  InputText,
  InputDate,
  MediaGallery,
  ImageUpload,
  RichTextEditor,
} from "./index.js";
import Select from "components/cms/core/Select";

import classes from "./EditForm.module.scss";

const dictionary = {
  slug: "Percorso URL",
  label: "Nome",
  eventDate: "Data dell'evento",
  thumbnail: "Anteprima",
  description: "Descrizione",
  publishedAt: "Data di pubblicazione",
  rawContent: "Contenuto",
  gallery: "Galleria immagini",
  eventType: "Tipologia evento",
  video: "Video della news",
  title: "Titolo",
  publishingDate: "Data di pubblicazione",
};

const ENUMS = {
  eventType: [
    { value: "event", label: "Evento" },
    { value: "wedding", label: "Wedding" },
  ],
};

const EditForm = (props) => {
  const { schema, lang, slug, isVisible, dispatchContent } = props;

  const { id } = useParams();

  const [content, setContent] = useState({});

  useEffect(() => {
    const fetchTranslation = async () => {
      const tContent = await Api.translations.getEntityTranslation(id, lang);
      setContent(tContent);
    };
    fetchTranslation();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatchContent(content);
    //eslint-disable-next-line
  }, [content]);

  const handleChange = (e) => {
    setContent({
      ...content,
      [e.target.name]: e.target.value,
    });
  };

  const onImageUpload = async (file, inputName) => {
    const form = new FormData();
    form.append("thumbnail", file);
    const res = await Api.media.uploadEntity(slug, form);
    setContent((prev) => ({
      ...prev,
      [inputName]: res,
    }));
  };

  const onGalleryUpload = async (items, inputName) => {
    const formData = new FormData();
    items.forEach((file) => {
      formData.append("gallery[]", file);
    });
    const res = await Api.media.uploadArray(slug, formData);
    setContent((prev) => ({
      ...prev,
      [inputName]: res[0],
    }));
  };

  return (
    <div
      className={classes.languageForm}
      style={{
        display: isVisible ? "block" : "none",
      }}
    >
      {schema.map((field) => {
        const { id, fieldName, fieldType, required } = field;
        switch (fieldType) {
          case "text":
            return (
              <InputText
                label={dictionary[fieldName] || fieldName}
                value={content[fieldName]}
                required={required}
                name={fieldName}
                key={`form-content-edit-${id}`}
                handleChange={handleChange}
              />
            );
          case "date":
            return (
              <InputDate
                label={dictionary[fieldName] || fieldName}
                value={content[fieldName]}
                name={fieldName}
                required={required}
                key={`form-content-edit-${id}`}
                handleChange={handleChange}
              />
            );
          case "file":
            return (
              <fieldset key={`form-content-edit-${id}`}>
                <label>{dictionary[fieldName] || fieldName}</label>
                <ImageUpload
                  content={content[fieldName]}
                  name={fieldName}
                  handleChange={onImageUpload}
                />
              </fieldset>
            );
          case "longtext":
            return (
              <fieldset key={`form-content-edit-${id}`}>
                <label>{dictionary[fieldName] || fieldName}</label>
                <RichTextEditor
                  content={content[fieldName]}
                  name={fieldName}
                  handleChange={handleChange}
                />
              </fieldset>
            );
          case "mediaGallery":
            return (
              <fieldset key={`form-content-edit-${id}`}>
                <label>{dictionary[fieldName] || fieldName}</label>
                <MediaGallery
                  content={content[fieldName]}
                  required={required}
                  name={fieldName}
                  handleChange={onGalleryUpload}
                />
              </fieldset>
            );
          case "enum":
            return (
              <fieldset key={`form-content-edit-${id}`}>
                <label>{dictionary[fieldName] || fieldName}</label>
                <Select
                  name={fieldName}
                  value={content[fieldName]}
                  handleChange={handleChange}
                  options={ENUMS[fieldName]}
                />
              </fieldset>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default EditForm;
