import { Switch, Route } from "react-router-dom";

import {
  CaseHistory,
  CaseHistoryDetail,
  Journal,
  JournalDetail,
  CustomPage,
} from "screens";

import { PublicLayout } from "components/layout";

const PublicRoutes = ({ pages }) => {
  return (
    <PublicLayout>
      <Switch>
        <Route exact path="/journal" component={Journal} />
        <Route exact path="/journal/:slug" component={JournalDetail} />
        <Route exact path="/case-history" component={CaseHistory} />
        <Route exact path="/case-history/:slug" component={CaseHistoryDetail} />
        {pages.length > 0 &&
          pages.map((page) => {
            const pagePath = page.slug === "homepage" ? "/" : `/${page.slug}`;
            return (
              <Route
                exact
                path={pagePath}
                component={CustomPage}
                key={`route-pages-${page.id}`}
              />
            );
          })}
      </Switch>
    </PublicLayout>
  );
};

export default PublicRoutes;
