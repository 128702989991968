import { Fragment, useEffect, useState } from "react";

import { Container, Loader } from "components/core";
import {
  Heading,
  Pagination,
  Paragraph,
  SplashImage,
} from "components/modules";

import CaseHistoryList from "./CaseHistoryList";
import CaseHistoryListItem from "./CaseHistoryListItem";
import axios from "axios";
import { useTranslation } from "react-i18next";


//const paragraph = `Quello che io posso fare per il tuo matrimonio concretamente? Pianificare, coordinare, ricercare e offrire validi spunti che possono contribuire a rendere ancora più speciale la tua giornata; in una parola occuparmi di tutta l’organizzazione di un matrimonio a Monza Brianza o ovunque tu voglia. Affidarsi a un professionista del matrimonio come il wedding planner, in Italia o all’estero, significa risparmiare tempo, ridurre lo stress, diminuire i costi, avvicinarsi maggiormente a quello che è il proprio matrimonio ideale e godersi in tutto e per tutto la bellezza di un giorno unico.`;

const CaseHistory = () => {
  const { t } = useTranslation();
 
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    totalItems: 124,
    currentPage: 1,
    itemsPerPage: 12,
    totalPages: 11,
  });

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/entities/list/${1}`, {
        data: {
          limit: pagination?.itemsPerPage,
          page: pagination?.currentPage,
        },
      })
      .then((res) => res.data)
      .then(({ success, data }) => {
        setPagination({
          ...pagination,
          totalItems: data?.totalItems,
          totalPages: Math.ceil(data?.totalItems / pagination?.itemsPerPage),
        });
        if (success) setList(data.items);
      })
      .catch((e) => console.error(e));
    setIsLoading(false);
    //eslint-disable-next-line
  }, [pagination?.currentPage]);

  const handlePageChange = (newPage) => {
    if (
      pagination?.currentPage < pagination?.totalPages &&
      pagination?.currentPage > 0
    )
      setPagination({
        ...pagination,
        currentPage: newPage,
      });
  };

  const renderListing = () => {
    if (isLoading) return <Loader />;

    return (
      <Fragment>
        <Container>
          <CaseHistoryList>
            {list.map((item) => (
              <CaseHistoryListItem
                item={item}
                key={`case-history-item-${item.id}`}
              />
            ))}
          </CaseHistoryList>
          <Pagination
            currentPage={pagination?.currentPage}
            totalPages={pagination?.totalPages}
            handlePageChange={handlePageChange}
          />
        </Container>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <SplashImage path= {process.env.PUBLIC_URL + "case_history/case-history_hero.jpg"} /> 
      <Container small>
        <Heading weight={1} content={ t('frontend.case-history.title') } />
        <Paragraph content={ t('frontend.case-history.paragraph') } />
      </Container>
      <Container>{renderListing()}</Container>
    </Fragment>
  );
};

export default CaseHistory;
