import { useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const CropImage = ({ src, onComplete }) => {
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [fileUrl, setFileUrl] = useState("");

  const imageRef = useRef(null);

  const onImageLoaded = (img) => {
    imageRef.current = img;
  };

  const elaborateNewImage = async (crop) => {
    if (crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        "newFile.jpeg"
      );
      onComplete(croppedImageUrl);
    }
  };

  const getCroppedImg = async (image, crop, fileName) => {
    const { current } = image;
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = current.naturalWidth / current.width;
    const scaleY = current.naturalHeight / current.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(fileUrl);
          setFileUrl(window.URL.createObjectURL(blob));
          resolve(window.URL.createObjectURL(blob));
        },
        "image/jpeg",
        1
      );
    });
  };

  return (
    <ReactCrop
      src={src}
      crop={crop}
      onImageLoaded={onImageLoaded}
      onChange={(newCrop) => setCrop(newCrop)}
      onComplete={elaborateNewImage}
    />
  );
};

export default CropImage;
