import { useEffect, useState } from "react";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  BlockGrid,
  BlockHeading,
  BlockNavLink,
  BlockExtLink,
  BlockParagraph,
  BlockImageText,
  BlockSplashImage,
  BlockSelector,
  Preview,
} from "./index.js";

import classes from "../EditPage.module.scss";
import { DialogBox } from "components/cms";
import Api from "api";

const EditTranslation = (props) => {
  const { lang, slug, isVisible, dispatchTranslation } = props;
  const [blocks, setBlocks] = useState([]);

  useEffect(() => {
    const fetchTranslation = async () => {
      const t = await Api.translations.getPageTranslation(slug, lang);
      setBlocks(t);
    };
    if (slug) fetchTranslation();
    //eslint-disable-next-line
  }, [slug]);

  useEffect(() => {
    dispatchTranslation(blocks, lang);
    //eslint-disable-next-line
  }, [blocks]);

  // const onCopyStructure = (e) => {
  //   e.preventDefault();
  //   copyBlockStructure(lang);
  // };

  const [gridModal, openGridModal] = useState(false);
  const [headingModal, openHeadingModal] = useState(false);
  const [navLinkModal, openNavLinkModal] = useState(false);
  const [extLinkModal, openExtLinkModal] = useState(false);
  const [imgTextModal, openImgTextModal] = useState(false);
  const [paragraphModal, openParagraphModal] = useState(false);
  const [splashImageModal, openSplashImageModal] = useState(false);

  const [selectedBlock, setSelectedBlock] = useState(null);

  const handleBlock = (index = null, block) => {
    const newBlockState = [...blocks];
    if (index >= 0) newBlockState[index] = block;
    else newBlockState.push(block);
    setBlocks(newBlockState);
  };

  const changeBlockOrder = (e, idx) => {
    function arraymove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
      return arr;
    }

    const newBlocks = arraymove([...blocks], idx, Number(e.target.value));
    setBlocks(newBlocks);
  };

  const deleteBlock = (index) => {
    const newBlockState = blocks.filter((_, idx) => idx !== index);
    setBlocks(newBlockState);
  };

  return (
    <section
      style={{
        display: isVisible ? "block" : "none",
      }}
    >
      {/* {lang !== "it_IT" && (
      <button onClick={onCopyStructure}>Copia struttura pagina</button>
    )} */}
      <div>
        <BlockSelector
          openGridModal={() => openGridModal(true)}
          openHeadingModal={() => openHeadingModal(true)}
          openNavLinkModal={() => openNavLinkModal(true)}
          openExtLinkModal={() => openExtLinkModal(true)}
          openImgTextModal={() => openImgTextModal(true)}
          openParagraphModal={() => openParagraphModal(true)}
          openSplashImageModal={() => openSplashImageModal(true)}
        />
        <Preview
          blocks={blocks}
          deleteBlock={deleteBlock}
          openGridModal={openGridModal}
          openHeadingModal={openHeadingModal}
          openNavLinkModal={openNavLinkModal}
          openExtLinkModal={openExtLinkModal}
          openImgTextModal={openImgTextModal}
          changeBlockOrder={changeBlockOrder}
          setSelectedBlock={setSelectedBlock}
          openParagraphModal={openParagraphModal}
          openSplashImageModal={openSplashImageModal}
        />
      </div>
      {paragraphModal && (
        <DialogBox
          closeDialog={() => openParagraphModal(false)}
          maxWidth={1200}
        >
          <BlockParagraph
            block={selectedBlock}
            allBlocks={blocks}
            handleBlock={handleBlock}
            openParagraphModal={openParagraphModal}
            setSelectedBlock={setSelectedBlock}
            classes={classes}
          />
        </DialogBox>
      )}
      {headingModal && (
        <DialogBox closeDialog={() => openHeadingModal(false)}>
          <BlockHeading
            block={selectedBlock}
            allBlocks={blocks}
            handleBlock={handleBlock}
            openHeadingModal={openHeadingModal}
            setSelectedBlock={setSelectedBlock}
            classes={classes}
          />
        </DialogBox>
      )}
      {imgTextModal && (
        <DialogBox closeDialog={() => openImgTextModal(false)}>
          <BlockImageText
            slug={slug}
            classes={classes}
            allBlocks={blocks}
            block={selectedBlock}
            handleBlock={handleBlock}
            openImgTextModal={openImgTextModal}
            setSelectedBlock={setSelectedBlock}
          />
        </DialogBox>
      )}
      {gridModal && (
        <DialogBox closeDialog={() => openGridModal(false)}>
          <BlockGrid
            block={selectedBlock}
            allBlocks={blocks}
            handleBlock={handleBlock}
            openGridModal={openGridModal}
            setSelectedBlock={setSelectedBlock}
            classes={classes}
            slug={slug}
          />
        </DialogBox>
      )}
      {splashImageModal && (
        <DialogBox closeDialog={() => openSplashImageModal(false)}>
          <BlockSplashImage
            block={selectedBlock}
            allBlocks={blocks}
            handleBlock={handleBlock}
            openSplashImageModal={openSplashImageModal}
            setSelectedBlock={setSelectedBlock}
            classes={classes}
            slug={slug}
          />
        </DialogBox>
      )}
      {navLinkModal && (
        <DialogBox closeDialog={() => openNavLinkModal(false)}>
          <BlockNavLink
            block={selectedBlock}
            allBlocks={blocks}
            handleBlock={handleBlock}
            openNavLinkModal={openNavLinkModal}
            setSelectedBlock={setSelectedBlock}
            classes={classes}
          />
        </DialogBox>
      )}
      {extLinkModal && (
        <DialogBox closeDialog={() => openExtLinkModal(false)}>
          <BlockExtLink
            block={selectedBlock}
            allBlocks={blocks}
            handleBlock={handleBlock}
            openExtLinkModal={openExtLinkModal}
            setSelectedBlock={setSelectedBlock}
            classes={classes}
          />
        </DialogBox>
      )}
    </section>
  );
};

export default EditTranslation;
