import { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Api from "api";
import { AdminContext } from "contexts/admin.context";
import classes from "./Header.module.scss";
import { Button } from "components/cms";
const Header = (props) => {
  const {
    collection: { slug, totalItems },
  } = props;
  const [count, setCount] = useState(0);
  const { adminBasePath } = useContext(AdminContext);
  const history = useHistory();
  useEffect(() => {
    const getEntityCount = async () => {
      const count = await Api.entities.count();
      setCount(count);
    };
    getEntityCount();
  }, []);
  const handleClick = () => {
    history.push(`${adminBasePath}/collections/${slug}/edit/${count + 1}`);
  };
  return (
    <Fragment>
      <div className={classes.userActions}>
        <p>Elementi totali trovati: {totalItems}</p>
        <Button handleClick={handleClick}>Aggiungi nuovo</Button>
      </div>
    </Fragment>
  );
};
export default Header;
