import axios from "axios";

const getList = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/collections/list`
  );
  if (data.success) return data.data;
};

const api = {
  getList,
};

export default api;
