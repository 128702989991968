import axios from "axios";

const get = async (path) => {
  if (path) {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/media`, {
      params: { path },
    });
    return data;
  }
  return "https://via.placeholder.com/1920x768";
};

const upload = async (slug, form) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/media/upload/${slug}`,
    form
  );
  if (data.success) return data.data;
};

const uploadArray = async (slug, formData) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/media/gallery/${slug}`,
    formData
  );
  if (data.success) return data.data;
};

const uploadEntity = async (slug, form) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/media/entities/upload/${slug}`,
    form
  );
  if (data.success) return data.data;
};

const uploadEntityArray = async (slug, formData) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/media/entities/gallery/${slug}`,
    formData
  );
  if (data.success) return data.data;
};

const api = {
  get,
  upload,
  uploadArray,
  uploadEntity,
  uploadEntityArray,
};

export default api;
