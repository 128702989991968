import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Api from "api";
import {
  PageInfo,
  SelectTranslation,
  EditTranslation,
} from "./EditPage/index.js";
import { Button, Toast } from "components/cms";
import classes from "./EditPage.module.scss";

const EditPage = () => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const currentRoute = location.pathname.split("/")[2];
  const [translations, setTranslations] = useState({});
  const [success, setSuccess] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [page, setPage] = useState({
    label: "",
    slug: "",
    isPublished: false,
    metaTitle: "",
    metaDescription: "",
  });

  useEffect(() => {
    const fetchPageData = async () => {
      const page = await Api.pages.getBySlug(params?.slug);
      setPage(page);
    };
    if (params?.slug) fetchPageData();
  }, [params?.slug]);

  const onSaveTranslation = (blocks, lang) => {
    setTranslations((prev) => ({
      ...prev,
      [lang]: blocks,
    }));
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    setIsSaving(true);
    const res = await Api.pages.create(page);
    const translationResult = await Api.translations.updateOrCreatePage(
      page.slug,
      translations
    );
    if (res && translationResult) setSuccess(true);
    setIsSaving(false);
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name === "isPublished")
      setPage((prev) => ({
        ...prev,
        isPublished: e.target.checked,
      }));
    else {
      setPage((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
      if (e.target.name === "label")
        setPage((prev) => ({
          ...prev,
          slug: e.target.value.toLocaleLowerCase().replace(/ /g, "-"),
        }));
    }
  };

  // const copyBlockStructure = async (lang) => {
  //   const confirm = window.confirm(
  //     "Attenzione, copiando la struttura della pagina verranno eliminati eventuali contenuti presenti. Procedere lo stesso?"
  //   );
  //   if (confirm) {
  //     setIsSaving(true);
  //     const oldTranslations = { ...translations };
  //     oldTranslations[lang] = translations["it_IT"];
  //     setTranslations(oldTranslations);
  //     await Api.translations.updateOrCreatePage(page.slug, oldTranslations);
  //     setSuccess(true);
  //     setIsSaving(false);
  //     window.location.reload();
  //   }
  // };

  const [activeTranslation, setTranslation] = useState("it_IT");

  const languages = {
    it_IT: {
      it_IT: "Italiano",
      en_US: "Inglese",
      fr_FR: "Francese",
    },
    en_US: {
      it_IT: "Italian",
      en_US: "English",
      fr_FR: "Francese",
    },
  };

  return (
    <div className={classes.editPage}>
      <h2>
        {currentRoute === "pages"
          ? `Modifica pagina  › ${page.label}`
          : "Crea nuova pagina"}
      </h2>
      <form onSubmit={handleSubmit}>
        <div className={classes.container}>
          <div>
            <PageInfo page={page} handleChange={handleChange} />

            <SelectTranslation
              activeTranslation={activeTranslation}
              setTranslation={setTranslation}
            />

            {Object.keys(languages).map((lang) => (
              <EditTranslation
                lang={lang}
                slug={page.slug}
                key={lang}
                isVisible={activeTranslation === lang}
                dispatchTranslation={onSaveTranslation}
              />
            ))}
          </div>
          <div className={classes.userActions}>
            <Button
              disabled={isSaving}
              variant="primary"
              handleClick={handleSubmit}
            >
              {t(`backend.core.save`)}
            </Button>
          </div>
        </div>
      </form>
      {success && (
        <Toast
          dismiss={() => setSuccess(false)}
          toastList={[{ id: 1, message: "Elemento salvato con successo!" }]}
        />
      )}
    </div>
  );
};

export default EditPage;
