import { Card } from "components/cms";
import classes from "./PageInfo.module.scss";

const PageInfo = (props) => {
  const { page, handleChange } = props;

  return (
    <Card>
      <div className={classes.pageInfo}>
        <h3>Informazioni pagina</h3>
        <fieldset>
          <input
            type="text"
            name="label"
            required
            disabled
            hidden
            value={page.label}
          />
        </fieldset>
        <fieldset>
          <input
            type="text"
            name="slug"
            disabled
            required
            hidden
            value={page.slug}
          />
        </fieldset>
        <fieldset>
          <label htmlFor="metaTitle">Titolo pagina</label>
          <input
            type="text"
            name="metaTitle"
            required
            value={page.metaTitle}
            onChange={handleChange}
          />
        </fieldset>
        <fieldset>
          <label htmlFor="metaDescription">Descrizione pagina</label>
          <input
            type="text"
            name="metaDescription"
            required
            value={page.metaDescription}
            onChange={handleChange}
          />
        </fieldset>
        <fieldset>
          <label htmlFor="isPublished">Pubblicare pagina?</label>
          <input
            type="checkbox"
            name="isPublished"
            checked={page.isPublished}
            onChange={handleChange}
          />
        </fieldset>
      </div>
    </Card>
  );
};

export default PageInfo;
