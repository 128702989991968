import { useEffect, useState } from "react";
import { useParams } from "react-router";

import Api from "api";

import classes from "./CollectionDetails.module.scss";
import { Header, InputText, EditForm } from "./CollectionDetails/index.js";
import { Button, Toast } from "components/cms";

const dictionary = {
  slug: "Percorso URL",
  label: "Nome",
  eventDate: "Data dell'evento",
  thumbnail: "Anteprima",
  description: "Descrizione",
  publishedAt: "Data di pubblicazione",
  rawContent: "Contenuto",
  gallery: "Galleria immagini",
  eventType: "Tipologia evento",
  video: "Video della news",
  title: "Titolo",
  publishingDate: "Data di pubblicazione",
};

const initialItemState = {
  label: "",
  slug: "",
};

const languages = {
  it_IT: {
    it_IT: "Italiano",
    en_US: "Italian",
  },
  en_US: {
    it_IT: "Inglese",
    en_US: "English",
  },
};

const CollectionDetails = (props) => {
  const { collectionId } = props;

  const { id } = useParams();

  const [item, setItem] = useState(initialItemState);
  const [collection, setCollection] = useState(initialItemState);
  const [schema, setSchema] = useState([]);
  const [translations, setTranslations] = useState({});
  const [originalName, setName] = useState("");
  const [success, setSuccess] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const currentLang = "it_IT";
  const [selectedLanguage, setLanguage] = useState("it_IT");

  useEffect(() => {
    const init = async () => {
      const SCHEMA = await Api.entities.getSchema(collectionId);
      const ENTITY = await Api.entities.getById(collectionId, id);

      setSchema(SCHEMA);
      setItem({ label: ENTITY.label || "", slug: ENTITY.slug || "" });
      setCollection({
        label: ENTITY.collection.label,
        slug: ENTITY.collection.slug,
      });
      setName(ENTITY.label || "Nuovo elemento");
    };

    init();
  }, [collectionId, id]);

  const handleEntityChange = (e) => {
    const newState = {
      ...item,
      [e.target.name]: e.target.value,
    };
    if (e.target.name === "label")
      newState.slug = e.target.value.toLocaleLowerCase().replace(/ /g, "-");
    setItem(newState);
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    setIsSaving(true);

    const entity = {
      ...item,
      collectionId,
    };

    const result = await Api.entities.updateOrCreate(id, entity);
    await Api.translations.updateOrCreateById(id, translations);
    if (result) setSuccess(true);
    setIsSaving(false);
  };

  const onSaveContent = (content, lang) => {
    const prevTranslations = { ...translations };
    prevTranslations[lang] = content;

    setTranslations(prevTranslations);
  };

  return (
    <div className={classes.adminCollectionEditItem}>
      <Header collectionName={collection.label} originalName={originalName} />
      <div className={classes.langSelector}>
        {Object.keys(languages).map((key) => {
          return (
            <button
              key={key}
              className={`${key === selectedLanguage ? classes.active : ""}`}
              onClick={() => setLanguage(key)}
            >
              {languages[key][currentLang]}
            </button>
          );
        })}
      </div>
      {schema.length > 0 && (
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <InputText
            label={dictionary["label"] || "Label"}
            value={item.label}
            name="label"
            handleChange={handleEntityChange}
          />
          <InputText
            label={dictionary["slug"] || "Slug"}
            value={item.slug}
            name="slug"
            handleChange={handleEntityChange}
          />
          {Object.keys(languages).map((key) => (
            <EditForm
              key={key}
              schema={schema}
              slug={item.slug}
              lang={key}
              isVisible={key === selectedLanguage}
              dispatchContent={(content) => onSaveContent(content, key)}
            />
          ))}
          <Button
            disabled={isSaving}
            variant="primary"
            handleClick={handleSubmit}
          >
            Salva
          </Button>
        </form>
      )}
      {success && (
        <Toast
          dismiss={() => setSuccess(false)}
          toastList={[
            {
              id: 1,
              message: "Elemento salvato con successo!",
            },
          ]}
        />
      )}
    </div>
  );
};

export default CollectionDetails;
