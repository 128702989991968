import axios from "axios";
// https://www.youtube.com/embed/ScMzIvxBSi4
const count = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/entities`);
  if (data.success) return data.data;
};

const getInCollection = async (collectionId, params) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/entities/list/${collectionId}`,
    { params }
  );
  if (data.success) return data.data;
};

const getById = async (collectionId, entityId) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/collections/id/${collectionId}/entity/${entityId}`
  );
  if (data.success) return data.data;
};

const getBySlug = async (slug) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/entities/slug/${slug}`
  );
  if (data.success) return data.data;
};

const getSchema = async (collectionId) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/entities/schema/${collectionId}`
  );
  if (data.success) return data.data;
};

const getThumbnail = async (entityId) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/entities/${entityId}/thumbnail`
  );
  if (data.success) {
    return data.data;
  }
};

const getGallery = async (entityId) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/entities/${entityId}/gallery`
  );
  if (data.success) {
    const galleryArray = [];
    for (const url in data.data) {
      galleryArray.push(data.data[url]);
    }
    return galleryArray;
  }
};

const deleteGalleryItem = async (entityId, galleryItemId) => {
  const { data } = await axios.delete(
    `${process.env.REACT_APP_API_URL}/entities/${entityId}/gallery/${galleryItemId}`
  );
  if (data.success) {
    const galleryArray = [];
    for (const url in data.data) {
      galleryArray.push(data.data[url]);
    }
    return galleryArray;
  }
};

const updateOrCreate = async (entityId, entity) => {
  const { data } = await axios({
    method: "POST",
    url: `${process.env.REACT_APP_API_URL}/entities/${entityId}`,
    data: entity,
  });
  return data.success;
};

const api = {
  // GET
  count,
  getById,
  getBySlug,
  getSchema,
  getGallery,
  getThumbnail,
  getInCollection,
  // POST
  updateOrCreate,
  // DELETE
  deleteGalleryItem,
};

export default api;
