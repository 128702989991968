import classes from "./Header.module.scss";

const Header = (props) => {
  const { collectionName, originalName } = props;
  
  return (
    <header className={classes.header}>
      <h2>
        Collezioni › {collectionName} › {originalName}
      </h2>
    </header>
  );
};

export default Header;
