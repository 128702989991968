import Api from "api";
import { LanguageContext } from "contexts/languageContext";
import { useContext, useEffect, useState } from "react";
import classes from "./BlogListItem.module.scss";

const BlogListItem = (props) => {
  const { label, id, slug } = props;
  const { lang } = useContext(LanguageContext);

  const [content, setContent] = useState();

  const [thumbnail, setThumbnail] = useState(
    process.env.PUBLIC_URL + "placeholder_journals.jpg"
  );

  useEffect(() => {
    const fetchTranslation = async () => {
      const post = await Api.translations.getEntityTranslation(id, lang);
      setContent(post);
    };

    fetchTranslation();

    //eslint-disable-next-line
  }, [lang]);

  useEffect(() => {
    if (content?.thumbnail)
      setThumbnail(
        `${process.env.REACT_APP_API_URL.replace(
          "/api",
          ""
        )}/${content.thumbnail.replace("media/", "")}`
      );
  }, [content]);

  if (!content) return null;

  return (
    <div className={classes.blogListItem}>
      <div className={classes.thumbnail}>
        <img src={thumbnail} alt={label} />
      </div>
      <div className={classes.content}>
        {content.publishingData && (
          <div className={classes.publishingDate}>{content.publishingData}</div>
        )}
        <h2>{content.title}</h2>
        <p
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: content.description }}
        />
        <a href={`/journal/${slug}`} className={classes.readMoreBtn}>
          Read More ››
        </a>
      </div>
    </div>
  );
};

export default BlogListItem;
