import classes from "./Select.module.scss";

const Select = (props) => {
  const {
    name = "custom-dropdown",
    value = "",
    handleChange = () => {},
    options = [],
  } = props;

  return (
    <select
      onChange={handleChange}
      value={value ? value : options[0].value}
      name={name}
      required
      className={classes.select}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default Select;
