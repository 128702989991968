import axios from "axios";

const getSettings = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/settings`);
  if (data.success) return data.data;
};

const api = {
  getSettings,
};

export default api;
