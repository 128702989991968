import ListItem from "./ListItem";
import classes from "./List.module.scss";
import { Button } from "components/cms";

const dictionary = {
  slug: "Slug per percorso URL",
  label: "Nome",
  eventDate: "Data dell'evento",
  thumbnail: "Anteprima",
  gallery: "Galleria",
  publishedAt: "Data di pubblicazione",
  content: "Contenuto",
  collectionId: "ID della collezione",
  collectionName: "Collezione",
};

const List = (props) => {
  const { collection } = props;

  const ListHeader = () => {
    return (
      <div className={classes.collectionListHeader}>
        {Object.keys(collection?.items[0]).map((key, index) => {
          if (key === "content" || key === "collectionId") return null;
          return (
            <div
              key={`admin-panel-collection-header-${key}-${index}`}
              data-type={key}
            >
              {dictionary[key] || key}
            </div>
          );
        })}
        <div data-type="actions">Modifica</div>
        <div data-type="actions">Elimina</div>
      </div>
    );
  };

  return (
    <div className={classes.list}>
      {collection?.items.length <= 0 ? (
        <div className={classes.emptyList}>
          <p>In questo momento non ci sono elementi in questa collezione.</p>
          <p>Clicca su Aggiungi nuovo per iniziare.</p>
        </div>
      ) : (
        <>
          <div className={classes.collectionList}>
            <ListHeader />
            {collection?.items.slice(0, 10).map((item) => {
              return (
                <ListItem
                  key={`admin-panel-collection-${item.id}-${item.slug}`}
                  item={item}
                  collectionName={collection.label}
                  collectionPath={collection.slug}
                />
              );
            })}
          </div>
          <Button>Salva</Button>
        </>
      )}
    </div>
  );
};

export default List;
