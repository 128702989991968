import classes from "./Pagination.module.scss";

const Pagination = (props) => {
  const {
    currentPage = 0,
    totalPages = 1,
    handlePageChange = () => {},
  } = props;

  if (totalPages <= 1) return null;

  return (
    <div className={classes.pagination}>
      <button
        className={classes.prevButton}
        disabled={currentPage <= 1}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        ‹
      </button>
      {[...Array(totalPages)].map((_, index) => {
        if (index + 1 < currentPage - 2 || index + 1 > currentPage + 2)
          return null;
        return (
          <button
            key={`pagination-btn-${index}`}
            className={`${classes.pageButton} ${
              currentPage === index + 1 ? classes.active : ""
            }`}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        );
      })}
      <button
        className={classes.nextButton}
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage >= totalPages}
      >
        ›
      </button>
    </div>
  );
};

export default Pagination;
