import { Button } from "components/cms";
import classes from "./BlockSelector.module.scss";
const BlockSelector = (props) => {
  const {
    openGridModal,
    openHeadingModal,
    openNavLinkModal,
    openImgTextModal,
    openExtLinkModal,
    openParagraphModal,
    openSplashImageModal,
  } = props;
  return (
    <div className={classes.blockSelector}>
      <h4>Aggiungi blocco</h4>
      <div>
        <Button handleClick={openHeadingModal}>Titolo</Button>
        <Button handleClick={openParagraphModal}>Testo</Button>
        <Button handleClick={openImgTextModal}>Immagine + testo</Button>
        <Button handleClick={openGridModal}>Galleria immagini</Button>
        <Button handleClick={openSplashImageModal}>
          Immagine a tutto schermo
        </Button>
        <Button handleClick={openNavLinkModal}>
          Collegamento pagina interna
        </Button>
        <Button handleClick={openExtLinkModal}>
          Collegamento pagina esterna
        </Button>
      </div>
    </div>
  );
};
export default BlockSelector;
