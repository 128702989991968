import { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Api from "api";
import { TrashIcon } from "assets/icons";

const BlockGrid = (props) => {
  const {
    block,
    allBlocks,
    handleBlock,
    openGridModal,
    setSelectedBlock,
    classes,
    slug,
  } = props;

  const selectedIndex = block ?? -1;
  const selectedBlock = block >= 0 ? allBlocks[block] : {};

  const [items, setItems] = useState(selectedBlock?.props?.items || []);
  const [columns, setColumns] = useState(selectedBlock?.props?.columns || 3);

  const uploadGallery = async (items) => {
    const formData = new FormData();
    items.forEach((file) => {
      formData.append("gallery[]", file);
    });
    const res = await Api.media.uploadArray(slug, formData);
    return res[0];
  };

  const handleSave = () => {
    handleBlock(selectedIndex, {
      blockType: "Grid",
      props: {
        items,
        columns,
      },
    });
    resetContent();
  };

  const handleChange = async (e) => {
    const added = await uploadGallery([...items, ...e.target.files]);
    const newFiles = [...items, ...added];
    setItems(newFiles);
  };

  const handleColumnChange = (e) => {
    setColumns(e.target.value);
  };

  const resetContent = () => {
    setItems([]);
    setColumns(3);
    openGridModal(false);
    setSelectedBlock(null);
  };

  const removeFile = (e, index) => {
    e.preventDefault();
    const newFiles = items.filter((_, idx) => index !== idx);
    setItems(newFiles);
  };

  const handleOnDragEnd = (res) => {
    const { source, destination } = res;
    function arrayMove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
      return arr;
    }

    const newState = arrayMove(items, source.index, destination.index);
    setItems(newState);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "lightgreen" : "grey",
    ...draggableStyle,
    top: "auto !important",
    left: "auto !important",
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "white",
    display: "flex",
    overflow: "auto",
  });

  return (
    <div className={classes.dialog}>
      <h3>Aggiungi galleria immagini</h3>
      <fieldset>
        <label htmlFor="gallery">Galleria immagini</label>
        <input type="file" name="gallery" multiple onChange={handleChange} />
      </fieldset>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="mainNavigation" direction="horizontal">
          {(provided, snapshot) => (
            <div
              className={classes.preview}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items.map((file, index) => {
                const src =
                  typeof file === "string"
                    ? `${process.env.REACT_APP_API_URL.replace(
                        "/api",
                        ""
                      )}/${file.replace("media/", "")}`
                    : URL.createObjectURL(file);
                return (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <img src={src} alt="" />
                        <button onClick={(e) => removeFile(e, index)}>
                          <TrashIcon />
                        </button>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <fieldset>
        <label htmlFor="columns">Numero di colonne</label>
        <select name="columns" value={columns} onChange={handleColumnChange}>
          <option value={3}>Tre colonne</option>
          <option value={4}>Quattro colonne</option>
          <option value={5}>Cinque colonne</option>
        </select>
      </fieldset>
      <div className={classes.dialogButtons}>
        <button onClick={resetContent}>Annulla</button>
        <button onClick={handleSave}>Salva</button>
      </div>
    </div>
  );
};

export default BlockGrid;
