import Api from "api";
import { InfoTooltip } from "components/cms";
import { useState } from "react";

const BlockSplashImage = (props) => {
  const {
    block,
    allBlocks,
    handleBlock,
    openSplashImageModal,
    setSelectedBlock,
    classes,
    slug,
  } = props;

  const selectedIndex = block ?? -1;
  const selectedBlock = block >= 0 ? allBlocks[block] : {};

  const [src, setSrc] = useState(selectedBlock?.props?.src || "");
  const [hasParallax, setParallax] = useState(
    selectedBlock?.props?.hasParallax || true
  );
  const [size, setSize] = useState(selectedBlock?.props?.size || "big");
  const [altText, setAltText] = useState(selectedBlock?.props?.altText || "");

  const uploadSingleImage = async (file) => {
    const form = new FormData();
    form.append("image", file);
    const res = await Api.media.upload(slug, form);
    setSrc(res);
  };

  const handleSave = () => {
    handleBlock(selectedIndex, {
      blockType: "SplashImage",
      props: {
        src,
        hasParallax,
        size,
        altText,
      },
    });
    resetContent();
  };

  const resetContent = () => {
    setSrc("");
    setParallax(true);
    setSize("big");
    setAltText("");
    openSplashImageModal(false);
    setSelectedBlock(null);
  };

  return (
    <div className={classes.dialog}>
      <h3>Aggiungi immagine a tutto schermo</h3>
      <fieldset>
        <label htmlFor="src">
          Immagine
          <InfoTooltip
            dialogBox={`Le dimensioni consigliate per le immagini sono di 1920x768px, in formato .png`}
          />
        </label>
        <input
          name="src"
          type="file"
          onChange={(e) => uploadSingleImage(e.target.files[0])}
        />
      </fieldset>
      <fieldset>
        <label htmlFor="altText">
          Testo ALT per immagine
          <InfoTooltip
            dialogBox={`Definisce una descrizione testuale alternativa dell'immagine.`}
          />
        </label>
        <input
          name="altText"
          type="text"
          value={altText}
          onChange={(e) => setAltText(e.target.value)}
        />
      </fieldset>
      <fieldset>
        <label htmlFor="size">
          Dimensione immagine
          <InfoTooltip
            dialogBox={`Il valore “Piccola” dona all'immagine le proporzioni 4:1, mentre il valore “Grande” le proporzioni 2.5:1`}
          />
        </label>
        <select
          name="size"
          onChange={(e) => setSize(e.target.value)}
          defaultValue={size}
        >
          <option value="big">Grande</option>
          <option value="small">Piccola</option>
        </select>
      </fieldset>
      <fieldset>
        <label htmlFor="hasParallax">
          Abilita effetto parallasse
          <InfoTooltip
            dialogBox={`Fornisce all'immagine di sfondo un effetto di riposizionamento in base allo scroll dell'utente`}
          />
        </label>
        <input
          type="checkbox"
          name="hasParallax"
          onChange={(e) => setParallax(e.target.checked)}
          checked={hasParallax}
        />
      </fieldset>
      <div className={classes.dialogButtons}>
        <button onClick={resetContent}>Annulla</button>
        <button onClick={handleSave}>Salva</button>
      </div>
    </div>
  );
};

export default BlockSplashImage;
