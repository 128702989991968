import classes from "./DialogBox.module.scss";

const DialogBox = (props) => {
  const { closeDialog, children, maxWidth = 800 } = props;

  return (
    <div className={classes.dialogBox} onClick={closeDialog}>
      <div
        className={classes.content}
        onClick={(e) => e.stopPropagation()}
        style={{
          maxWidth: `${maxWidth}px`,
        }}
      >
        <div className={classes.closeBtn} onClick={closeDialog}>
          &times;
        </div>
        {children}
      </div>
    </div>
  );
};

export default DialogBox;
