import axios from "axios";
import { useEffect, useState } from "react";

import { Button, Container } from "components/core";
import styles from "./Form.module.scss";
import { Paragraph } from "components/modules";

const initialState = {
  name: "",
  email: "",
  subject: "",
  message: "",
};
const initialOpts = {
  rows: [
    {
      fields: [
        {
          type: "text",
          label: "Nome",
          name: "name",
          required: true,
          defaultValue: "",
        },
        {
          type: "email",
          label: "Email",
          name: "email",
          required: true,
          defaultValue: "",
        },
        {
          type: "text",
          label: "Soggetto",
          name: "subject",
          required: true,
          defaultValue: "",
        },
      ],
    },
    {
      fields: [
        {
          type: "textarea",
          label: "Messaggio",
          name: "message",
          required: true,
          defaultValue: "",
        },
      ],
    },
  ],
};

const Form = () => {
  const [state, setState] = useState(initialState);
  const [moduleOpts, setOpts] = useState(initialOpts);
  const [isSending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setState(initialState);
    setOpts(initialOpts);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    // FORM SUBMIT
    axios
      .post(`${process.env.REACT_APP_API_URL}/mail/send`, state)
      .then((res) => res.data)
      .then(({ success, data }) => {
        if (success) setSuccess(true);
        setSending(false);
      })
      .catch((err) => {
        console.error(err);
        setSending(false);
      });
    // ON SUCCESS
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <Container>
      {success && (
        <Paragraph
          content="Messaggio inviato con successo!"
          alignText="center"
        />
      )}
      <form className={styles.form} onSubmit={handleSubmit}>
        {moduleOpts?.rows?.map((row, rowIndex) => {
          return (
            <div className={styles.col} key={`form-row-${rowIndex}`}>
              {row?.fields.map(
                ({ type, label, name, required }, fieldIndex) => {
                  if (type === "textarea")
                    return (
                      <fieldset key={`form-row-${rowIndex}-col-${fieldIndex}`}>
                        <textarea
                          aria-label={label}
                          key={`form-field-${name}`}
                          name={name}
                          required={required}
                          onChange={handleChange}
                          value={state[name]}
                          disabled={isSending}
                        />
                        <label htmlFor={name}>{label}</label>
                      </fieldset>
                    );
                  return (
                    <fieldset key={`form-row-${rowIndex}-col-${fieldIndex}`}>
                      <input
                        type={type}
                        aria-label={label}
                        key={`form-field-${name}`}
                        name={name}
                        required={required}
                        onChange={handleChange}
                        value={state[name]}
                        disabled={isSending}
                      />
                      <label htmlFor={name}>{label}</label>
                    </fieldset>
                  );
                }
              )}
            </div>
          );
        })}
        <div className={styles.row}>
          <Button disabled={isSending}>Invia ››</Button>
        </div>
      </form>
    </Container>
  );
};

export default Form;
