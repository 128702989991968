import axios from "axios";

const get = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/users`
  );
  if (data.success) return data.data;
};
const getById = async (id) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/users/${id}`
  );
  return data;
};

const getByRole = async (role) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/admin/users/role/${role}`
  );
  return data;
};

const create = async (user) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/admin/users`,
    user
  );
  return data;
};

const api = {
  // GET
  get,
  getById,
  getByRole,
  // POST
  create,
};

export default api;
