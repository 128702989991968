import classes from "./VideoEmbed.module.scss";

const VideoEmbed = ({ video, label }) => {
  return (
    <div className={classes.videoEmbed}>
      <iframe src={video} title={label} />
    </div>
  );
};

export default VideoEmbed;
