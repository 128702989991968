import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Api from "api";
import classes from "./Users.module.scss";
import CreateUser from "./CreateUser";
import { CmsUserContext } from "contexts/cmsUserContext";
import { Button, Card } from "components/cms";
import { Page } from "components/cms/core";

const Users = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user } = useContext(CmsUserContext);

  const [users, setUsers] = useState([]);
  const [isFormOpen, setFormOpen] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      const result = await Api.admin.users.get();
      setUsers(result);
    };

    fetchUsers();
  }, []);

  const User = ({ user }) => {
    return (
      <div className={classes.userItem}>
        <div>{user.id || "—"}</div>
        <div>{user.email || "—"}</div>
        <div>{user.firstName || "—"}</div>
        <div>{user.lastName || "—"}</div>
        <div>{user.role || "—"}</div>
      </div>
    );
  };

  user.role !== "SUPERADMIN" && history.replace("/admin");

  const renderList = () => {
    if (!users.length) return null;

    return (
      <div className={classes.userList}>
        <div className={classes.userItem}>
          <div>ID</div>
          <div>Email</div>
          <div>Nome</div>
          <div>Cognome</div>
          <div>Ruolo</div>
        </div>
        {users.map((user) => (
          <User key={user.id} user={user} />
        ))}
      </div>
    );
  };

  return (
    <Page title={t(`backend.sidebar.users`)}>
      <Card>
        <Card.Header>
          <h3>Utenti backend attivi</h3>
          <Button handleClick={() => setFormOpen(true)} variant="primary">
            Crea utente
          </Button>
        </Card.Header>
        {renderList()}
      </Card>
      {isFormOpen && <CreateUser handleClose={() => setFormOpen(false)} />}
    </Page>
  );
};

export default Users;
