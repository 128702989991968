import { createContext, useEffect, useState } from "react";
import axios from "axios";

export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const [settings, setSettings] = useState();
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/settings`)
      .then((res) => res.data)
      .then(({ data }) => {
        const state = {};
        data.map((obj) => (state[obj.key] = obj.value));
        setSettings(state);
      });
  }, []);

  return (
    <AdminContext.Provider value={{ ...settings, theme, setTheme }}>
      {children}
    </AdminContext.Provider>
  );
};
