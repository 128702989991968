import { Container } from "components/core";
import classes from "./ExtLink.module.scss";

const ExtLink = (props) => {
  const { path, label } = props;

  const href = path.substr(0, 4) === "http" ? path : `http://${path}`;

  return (
    <Container>
      <a
        href={href}
        className={classes.extLink}
        target="_blank"
        rel="noreferrer"
      >
        {label} ››
      </a>
    </Container>
  );
};

export default ExtLink;
