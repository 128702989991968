import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { AdminContext } from "contexts/admin.context";

import classes from "./CreatePage.module.scss";
import Api from "api";

const CreatePage = () => {
  const history = useHistory();
  const { adminBasePath } = useContext(AdminContext);

  const [page, setPage] = useState({
    label: "",
    slug: "",
    metaTitle: "",
    metaDescription: "",
    isPublished: false,
  });

  const handleChange = (e) => {
    let value =
      e.target.name === "isPublished" ? e.target.checked : e.target.value;
    const newState = {
      ...page,
      [e.target.name]: value,
    };
    if (e.target.name === "label")
      newState.slug = e.target.value.toLocaleLowerCase().replace(/ /g, "-");
    setPage(newState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await Api.pages.create(page);
    if (res) {
      window.location.reload();
      setTimeout(() => {
        history.push(`${adminBasePath}/pages/edit/${page.slug}`);
      }, 1000);
    }
  };

  return (
    <div className={classes.createPage}>
      <h2>Crea nuova pagina</h2>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <label htmlFor="label">Nome pagina</label>
          <input
            type="text"
            name="label"
            required
            value={page.label}
            onChange={handleChange}
          />
        </fieldset>
        <fieldset>
          <label htmlFor="slug">Percorso URI pagina</label>
          <input
            type="text"
            name="slug"
            disabled
            required
            value={page.slug}
            onChange={handleChange}
          />
        </fieldset>
        <fieldset>
          <label htmlFor="metaTitle">Titolo pagina</label>
          <input
            type="text"
            name="metaTitle"
            required
            value={page.metaTitle}
            onChange={handleChange}
          />
        </fieldset>
        <fieldset>
          <label htmlFor="metaDescription">Descrizione pagina</label>
          <input
            type="text"
            name="metaDescription"
            required
            value={page.metaDescription}
            onChange={handleChange}
          />
        </fieldset>
        <fieldset>
          <label htmlFor="isPublished">Pubblicare pagina?</label>
          <input
            type="checkbox"
            name="isPublished"
            checked={page.isPublished}
            onChange={handleChange}
          />
        </fieldset>
        <button type="submit">Salva</button>
      </form>
    </div>
  );
};

export default CreatePage;
