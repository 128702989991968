import { useState } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

import classes from "./RichTextEditor.module.scss";

const RichTextEditor = (props) => {
  const { content: value, name, handleChange } = props;

  const [content, setContent] = useState(
    value
      ? EditorState.createWithContent(stateFromHTML(value))
      : EditorState.createEmpty()
  );

  const onEditorStateChange = (editorState) => {
    const data = {
      target: {
        name,
        value: stateToHTML(content.getCurrentContent()),
      },
    };
    setContent(editorState);
    handleChange(data);
  };

  return (
    <Editor
      editorState={content}
      onEditorStateChange={onEditorStateChange}
      wrapperClassName={classes.wrapper}
      editorClassName={classes.editor}
      toolbarClassName={classes.toolbar}
      toolbar={{
        options: ["inline", "list", "history", "link"],
        inline: { inDropdown: false },
        list: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true },
      }}
    />
  );
};

export default RichTextEditor;
