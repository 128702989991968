import classes from "./Login.module.scss";
import logo from "assets/img/logo-vert.svg";
import { useContext, useState } from "react";
import Api from "api";
import { useHistory } from "react-router-dom";
import { CmsUserContext } from "contexts/cmsUserContext";

const Login = () => {
  const { setUser } = useContext(CmsUserContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await Api.admin.auth.login(email, password);
    if (res) {
      setUser({
        email: res.email,
        firstName: res.firstName,
        lastName: res.lastName,
        role: res.role,
      });
      history.push("/admin");
    }
  };

  return (
    <div className={classes.login}>
      <form onSubmit={handleSubmit}>
        <div className={classes.brand}>
          <img src={logo} alt="" />
        </div>
        <h1>Login</h1>

        <fieldset>
          <label htmlFor="email">
            <input
              type="email"
              name="email"
              value={email}
              required
              className={email.length > 0 ? classes.noPlaceholder : ""}
              onChange={(e) => setEmail(e.target.value)}
            />
            <span>Email</span>
          </label>
        </fieldset>

        <fieldset>
          <label htmlFor="password">
            <input
              type="password"
              name="password"
              value={password}
              required
              className={password.length > 0 ? classes.noPlaceholder : ""}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span>Password</span>
          </label>
        </fieldset>

        <button type="submit">Invia</button>
      </form>
    </div>
  );
};

export default Login;
