import classes from "./Page.module.scss";

const Page = ({ children, title = null }) => {
  return (
    <div className={classes.page}>
      {title && <h2>{title}</h2>}
      <div>{children}</div>
    </div>
  );
};

export default Page;
