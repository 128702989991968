import classes from "./Hero.module.scss";

const Hero = (props) => {
  const { thumbnail, title, publishedAt } = props;

  return (
    <div className={classes.hero}>
      <img src={thumbnail} alt={title} />
      <div>
        <h1>{title}</h1>
        <p>{publishedAt}</p>
      </div>
    </div>
  );
};

export default Hero;
