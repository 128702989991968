import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import IconTrash from "assets/icons/trash";
import IconPencil from "assets/icons/pencil";
import { AdminContext } from "contexts/admin.context";
import styles from "./ListItem.module.scss";
import axios from "axios";

const ListItem = ({ item, collectionPath }) => {
  const [state, setState] = useState();

  const { adminBasePath } = useContext(AdminContext);

  useEffect(() => {
    setState(item);
  }, [item]);

  const handleDeleteItem = () => {
    const userIsSure = window.confirm(
      `Sei sicuro di voler eliminare l'elemento “${state.label}”?\n\nQuesta operazione non è reversibile.`
    );
    if (userIsSure)
      axios
        .delete(`${process.env.REACT_APP_API_URL}/entities/${item.id}`)
        .then((res) => res.data)
        .then(({ success }) => {
          if (success) window.location.reload();
        })
        .catch((e) => console.error(e));
  };

  if (!state) return null;

  return (
    <div className={styles.collectionListItem}>
      {Object.keys(state).map((key) => {
        if (key === "content" || key === "collectionId") return null;
        return (
          <div key={`collection-row-item-${state?.id}-${key}`} data-type={key}>
            {key === "thumbnail" ? (
              <img src={state[key]} alt="" />
            ) : (
              <p>{state[key]}</p>
            )}
          </div>
        );
      })}

      <div className={styles.editItem}>
        <Link
          to={`${adminBasePath}/collections/${collectionPath}/edit/${state.id}`}
        >
          <IconPencil />
        </Link>
      </div>
      <div className={styles.deleteItem}>
        <button>
          <IconTrash onClick={handleDeleteItem} />
        </button>
      </div>
    </div>
  );
};

export default ListItem;
