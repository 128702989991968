import { useEffect, useState } from "react";
import axios from "axios";

import classes from "./CollectionList.module.scss";
import { Filters, Header, List, Pagination } from "./CollectionList/index.js";
import { Card } from "components/cms";

const initialCollectionState = {
  id: null,
  label: "",
  slug: "",
  items: [],
  totalItems: 0,
};

const CollectionList = (props) => {
  const { collectionId } = props;

  const [collection, setCollection] = useState(initialCollectionState);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState({ key: "id", direction: "ASC" });

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/entities/list/${collectionId}`, {
        params: { limit, page, sort },
      })
      .then((res) => res.data)
      .then(({ data }) => setCollection(data))
      .then(() => setLoading(false))
      .catch((e) => console.error(e));
  }, [collectionId, limit, page, sort]);

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
  };
  const handleSortChange = (e) => {
    if (sort.key === e.target.value)
      setSort({
        ...sort,
        direction: sort.direction === "ASC" ? "DESC" : "ASC",
      });
    else
      setSort({
        key: e.target.value,
        direction: "ASC",
      });
  };

  return (
    <div className={classes.adminCollection}>
      <h2 className={classes.breadcrumbs}>Collezioni › {collection.label}</h2>
      <Card>
        <Header collection={collection} />
        {collection.items.length >= 20 && (
          <Filters
            sort={sort}
            handleLimitChange={handleLimitChange}
            handleSortChange={handleSortChange}
          />
        )}
        {isLoading ? "Loading..." : <List collection={collection} />}
        <Pagination
          page={page}
          setPage={setPage}
          totalPages={Math.ceil(collection.totalItems / limit)}
        />
      </Card>
    </div>
  );
};

export default CollectionList;
