import { Fragment, useContext, useEffect, useState } from "react";

import { Button, Container } from "components/core";
import { Grid, Paragraph, SplashImage } from "components/modules";
import CaseHistoryLabel from "./CaseHistoryLabel";
import axios from "axios";
import { useParams } from "react-router";
import Api from "api";
import { LanguageContext } from "contexts/languageContext";

const CaseHistoryDetail = () => {
  const [item, setItem] = useState({});
  const [content, setContent] = useState({});
  const { slug } = useParams();
  const { lang } = useContext(LanguageContext);

  useEffect(() => {
    const fetchCaseHistory = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/entities/slug/${slug}`
      );

      if (data.success) {
        setItem({
          ...data.data,
        });
      }
    };

    fetchCaseHistory();
    //eslint-disable-next-line
  }, [slug]);

  useEffect(() => {
    const fetchTranslation = async () => {
      const content = await Api.translations.getEntityTranslation(
        item.id,
        lang
      );
      setContent(content);
    };
    if (item.id && lang) fetchTranslation();
  }, [item, lang]);

  return (
    <Fragment>
      <SplashImage src={content?.thumbnail} />
      <CaseHistoryLabel
        eventType={content?.eventType}
        eventDate={content?.eventDate}
        label={content?.title}
      />
      <Container small>
        <Paragraph content={content?.description} />
      </Container>
      <Container>
        <Grid items={content?.gallery} columns={4} hasLightbox={true} />
        <Button center link="/case-history">
          ‹‹ Torna
        </Button>
      </Container>
    </Fragment>
  );
};

export default CaseHistoryDetail;
