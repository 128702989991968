import classes from "./CaseHistoryLabel.module.scss";

const CaseHistoryLabel = (props) => {
  const { eventType, eventDate, label } = props;
  return (
    <div className={classes.caseHistoryLabel}>
      <div className={classes.wrapper}>
        <div className={classes.info}>
          {eventType && <span className={classes.name}>{eventType}</span>}
          {eventDate && <span className={classes.date}>{eventDate}</span>}
        </div>
        {label && <div className={classes.label}>{label}</div>}
      </div>
    </div>
  );
};

export default CaseHistoryLabel;
