import Api from "api";
import { useState } from "react";
import classes from "./CreateUser.module.scss";

const CreateUser = ({ handleClose }) => {
  const [user, setUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    confirm: "",
    role: "",
  });
  const [errors, setErrors] = useState({
    generic: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setUser((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await Api.admin.users.create(user);
    if (!response.success) setErrors(response.errors);
    else {
      handleClose();
      window.location.reload();
    }
  };
  return (
    <div className={classes.createUser} onClick={handleClose}>
      <form
        action=""
        onSubmit={handleSubmit}
        onClick={(e) => e.stopPropagation()}
      >
        <h2>Crea utente</h2>
        {errors.generic && (
          <div className={classes.errors}>{errors.generic}</div>
        )}
        {/* Email */}
        {errors.email && <div className={classes.errors}>{errors.email}</div>}
        <fieldset>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            required
            onChange={handleChange}
            value={user.email}
          />
        </fieldset>
        {/* firstName */}
        <fieldset>
          <label htmlFor="firstName">Nome</label>
          <input
            type="text"
            name="firstName"
            required
            onChange={handleChange}
            value={user.firstName}
          />
        </fieldset>
        {/* lastName */}
        <fieldset>
          <label htmlFor="lastName">Cognome</label>
          <input
            type="text"
            name="lastName"
            required
            onChange={handleChange}
            value={user.lastName}
          />
        </fieldset>
        {/* role */}
        <fieldset>
          <label htmlFor="role">Ruolo</label>
          <select
            name="role"
            onChange={handleChange}
            value={user.role}
            required
          >
            <option value="USER">User</option>
            <option value="ADMIN">Admin</option>
          </select>
        </fieldset>

        {errors.password && (
          <div className={classes.errors}>{errors.password}</div>
        )}
        {/* password */}
        <fieldset>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            required
            onChange={handleChange}
            value={user.password}
          />
        </fieldset>
        {/* confirm */}
        <fieldset>
          <label htmlFor="confirm">Conferma password</label>
          <input
            type="password"
            name="confirm"
            required
            onChange={handleChange}
            value={user.confirm}
          />
        </fieldset>
        <div>
          <button type="reset" onClick={handleClose}>
            Annulla
          </button>
          <button type="submit">Salva</button>
        </div>
      </form>
    </div>
  );
};

export default CreateUser;
