import axios from "axios";

const login = async (email, password) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/admin/auth/login`,
    {
      email,
      password,
    }
  );
  if (data.success) {
    localStorage.setItem("sv_user_token", JSON.stringify(data.data));
    return data.data;
  }
};

const logout = async () => {
  localStorage.removeItem("sv_user_token");
};

const getUserFromStorage = async () => {
  return localStorage.getItem("sv_user_token");
};

const api = {
  login,
  logout,
  getUserFromStorage,
};

export default api;
