const InputDate = (props) => {
  const { label = "", name = "", value = "", handleChange = () => {} } = props;

  return (
    <fieldset>
      <label htmlFor={label.toLowerCase()}>{label}</label>
      <input
        type="date"
        name={name}
        aria-label={label.toLowerCase()}
        value={value}
        onChange={handleChange}
        required
      />
    </fieldset>
  );
};

export default InputDate;
