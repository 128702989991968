import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useRef, useState } from "react";
import { Expo } from "gsap/gsap-core";
import gsap from "gsap";

import { Container } from "components/core";
import classes from "./ImageAndText.module.scss";

const ImageAndText = (props) => {
  const {
    text,
    title = "",
    image,
    altText = "",
    isReversed = false,
    biggerText = false,
  } = props;
  const controls = useAnimation();
  const { ref, inView } = useInView();
  const tl = useState(() => new gsap.timeline({ yoyo: false }));
  const [visible, setVisible] = useState(false);
  const img = useRef();
  const imgInnerContainer = useRef();
  const imgContainer = useRef();

  const src = image
    ? `${process.env.REACT_APP_API_URL.replace("/api", "")}/${image.replace(
        "media/",
        ""
      )}`
    : "https://via.placeholder.com/1920x768";

  const variants = {
    hidden: {
      y: 100,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 2,
      },
    },
  };

  const animate = () => {
    tl[0]
      .from(imgContainer.current, 1.5, {
        height: 0,
        opacity: 0,
        ease: Expo.easeInOut,
      })
      .from(
        img.current,
        1.35,
        {
          opacity: 0,
          scale: 1.8,
          ease: Expo.easeOut,
        },
        1.45
      )
      .to(
        imgInnerContainer.current,
        1.25,
        {
          height: 0,
          opacity: 0.75,
          ease: Expo.easeInOut,
        },
        1.25
      );
  };

  useEffect(() => {
    if (inView) {
      controls.start("visible");
      if (!visible) animate();
      setVisible(true);
    }

    //eslint-disable-next-line
  }, [controls, inView]);

  return (
    <Container small>
      <div
        className={`${classes.container} ${
          isReversed ? classes.reversed : ""
        } ${biggerText ? classes.biggerText : ""}`}
      >
        <div
          className={`${classes.image} 
      ${visible ? classes.visible : ""}`}
        >
          <img src={src} alt={altText} />
          <div ref={imgContainer}>
            <div ref={imgInnerContainer}></div>
            <img ref={img} src={src} alt={altText} />
          </div>
        </div>
        <motion.div
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={variants}
          className={classes.text}
        >
          {title.length > 0 && <h3>{title}</h3>}
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </motion.div>
      </div>
    </Container>
  );
};

export default ImageAndText;
