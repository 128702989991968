import classes from "./Button.module.scss";

const Button = (props) => {
  const { children, variant, handleClick = () => {}, disabled = false } = props;
  return (
    <button
      className={`${classes.button} ${
        variant ? classes[variant] : classes.primary
      }`}
      onClick={(e) => {
        e.preventDefault();
        handleClick();
      }}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
