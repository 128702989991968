import { useState } from "react";

const BlockHeading = (props) => {
  const {
    block,
    allBlocks,
    handleBlock,
    openHeadingModal,
    setSelectedBlock,
    classes,
  } = props;

  const selectedIndex = block ?? -1;
  const selectedBlock = block >= 0 ? allBlocks[block] : {};

  const [content, setContent] = useState(selectedBlock?.props?.content || "");
  const [weight, setWeight] = useState(selectedBlock?.props?.weight || 2);
  const [wrapperSize, setWrapperSize] = useState(
    selectedBlock?.wrapperSize || ""
  );

  const handleSave = () => {
    handleBlock(selectedIndex, {
      blockType: "Heading",
      wrapperSize,
      props: {
        content,
        weight,
      },
    });
    resetContent();
  };

  const resetContent = () => {
    setContent("");
    setWrapperSize("");
    openHeadingModal(false);
    setSelectedBlock(null);
  };

  return (
    <div className={classes.dialog}>
      <h3>Aggiungi titolo</h3>
      <fieldset>
        <label htmlFor="content">Contenuto</label>
        <input
          name="content"
          type="text"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </fieldset>
      <fieldset>
        <label htmlFor="weight">Dimensione titolo</label>
        <select
          name="weight"
          onChange={(e) => setWeight(e.target.value)}
          defaultValue={weight}
        >
          <option value="1">Titolo pagina (h1)</option>
          <option value="2">Titolo (h2)</option>
          <option value="3">Sottotitolo (h3)</option>
        </select>
      </fieldset>
      <fieldset>
        <label htmlFor="wrapper">Dimensione contenitore</label>
        <select
          name="wrapperSize"
          onChange={(e) => setWrapperSize(e.target.value)}
          defaultValue={wrapperSize}
        >
          <option value="small">Stretta</option>
          <option value="large">Larga</option>
          <option value="none">Nessuna</option>
        </select>
      </fieldset>
      <div className={classes.dialogButtons}>
        <button onClick={resetContent}>Annulla</button>
        <button onClick={handleSave}>Salva</button>
      </div>
    </div>
  );
};

export default BlockHeading;
