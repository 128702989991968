import { useState, useEffect } from "react";
import classes from "./Toast.module.scss";
import { CheckboxIcon } from "assets/icons";

const Toast = (props) => {
  const {
    toastList,
    position = "topRight",
    variant = "info",
    autoDeleteTime = 5000,
    dismiss,
  } = props;
  const [list, setList] = useState(toastList);

  useEffect(() => {
    setList(toastList);
  }, [toastList, list]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (toastList.length && list.length) {
        dismiss();
      }
    }, autoDeleteTime);

    return () => {
      clearInterval(interval);
    };

    //eslint-disable-next-line
  }, []);

  return (
    <div
      className={`${classes.container} ${classes[position]} ${classes[variant]}`}
    >
      {list.map((toast, i) => (
        <div className={`${classes.toast} ${classes[position]}`} key={i}>
          {toast.icon && (
            <div className={classes.image}>
              <img src={toast.icon} alt="" />
            </div>
          )}
          <div>
            {toast.title && <p className={classes.title}>{toast.title}</p>}{" "}
            <p className={classes.message}>
              <span>
                <CheckboxIcon />
              </span>
              {toast.message}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Toast;
