import { createContext, useEffect, useState } from "react";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [lang, setLang] = useState();

  useEffect(() => {
    var userLang = navigator.language || navigator.userLanguage;
    const arr = userLang.split("-");
    const lang = arr[0];
    const dialect = arr[1] ? arr[1] : arr[0];
    //console.log(`${lang}_${dialect.toUpperCase()}`)
    if (arr.length) setLang(`${lang}_${dialect.toUpperCase()}`);
  }, []);

  return (
    <LanguageContext.Provider value={{ lang, setLang }}>
      {children}
    </LanguageContext.Provider>
  );
};
