import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import classes from "./NotFound.module.scss";

const NotFound = () => {
  const history = useHistory();
  const [count, setCount] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(true);
    }, 1500);

    return () => {
      clearInterval(interval);
    };
    //eslint-disable-next-line
  }, []);

  if (!count) return null;

  return (
    <div className={classes.notFound}>
      <h1>Page not found</h1>
      <a
        href="/"
        onClick={(e) => {
          e.preventDefault();
          history.goBack();
        }}
      >
        ‹‹ Torna indietro
      </a>
    </div>
  );
};

export default NotFound;
