import {
  AdminHeader,
  AdminMain,
  AdminSidebar,
  ScrollToTop,
} from "components/cms";
import styles from "./AdminLayout.module.scss";

const AdminLayout = ({ children }) => {
  return (
    <div className={styles.adminLayout}>
      <AdminHeader />
      <main className={styles.main}>
        <AdminSidebar />
        <AdminMain>{children}</AdminMain>
      </main>
      <ScrollToTop />
    </div>
  );
};

export default AdminLayout;
