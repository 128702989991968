import { default as collections } from "./collections";
import { default as entities } from "./entities";
import { default as pages } from "./pages";
import { default as settings } from "./settings";
import { default as media } from "./media";
import { default as translations } from "./translations";
// Admin
import { default as users } from "./admin/users";
import { default as auth } from "./admin/auth";

const admin = {
  users,
  auth,
};

const Api = {
  collections,
  entities,
  pages,
  media,
  translations,
  settings,
  admin,
};

export default Api;
