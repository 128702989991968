import classes from "./SelectTranslation.module.scss";

const languages = {
  it_IT: {
    it_IT: "Italiano",
    en_US: "Inglese",
    fr_FR: "Francese",
  },
  en_US: {
    it_IT: "Italian",
    en_US: "English",
    fr_FR: "Francese",
  },
};

const currentLang = "it_IT";

const SelectTranslation = ({ activeTranslation, setTranslation }) => {
  const onSelectLanguage = (e, lang) => {
    e.preventDefault();
    setTranslation(lang);
  };

  return (
    <div className={classes.container}>
      {Object.keys(languages).map((lang) => (
        <button
          key={lang}
          onClick={(e) => onSelectLanguage(e, lang)}
          className={activeTranslation === lang ? classes.active : ""}
        >
          {languages[currentLang][lang]}
        </button>
      ))}
    </div>
  );
};

export default SelectTranslation;
