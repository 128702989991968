import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Card, Button } from "components/cms";
import { Page } from "components/cms/core";

import classes from "./Navigation.module.scss";

const dummyNav = [
  // { id: 1, url: "/homepage", label: "Home" },
  // { id: 2, url: "/simona-villa", label: "Simona Villa" },
  // { id: 3, url: "/wedding-planner", label: "Wedding Planner" },
  // { id: 4, url: "/wild-charm-wedding", label: "The Wild Charm Wedding" },
  // { id: 5, url: "/events", label: "Events" },
  // { id: 6, url: "/locations", label: "Locations" },
  // { id: 7, url: "/case-history", label: "Case History" },
  // { id: 8, url: "/journal", label: "Journal" },
  // { id: 9, url: "/contacts", label: "Contacts" },
];

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: isDragging ? "lightgreen" : "white",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "white",
  overflow: "auto",
});

const NavCard = (props) => {
  const { id, url, label, index } = props;
  return (
    <Draggable draggableId={id.toString()} index={index}>
      {(provided, snapshot) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <span>{label}</span>
          <span>Percorso URL: {url}</span>
        </li>
      )}
    </Draggable>
  );
};

const Navigation = () => {
  const { t } = useTranslation();
  const [mainNavigation, setMainNavigation] = useState(dummyNav);

  const handleOnDragEnd = ({ source, destination }) => {
    function arrayMove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
      return arr;
    }
    const newState = arrayMove(mainNavigation, source.index, destination.index);
    setMainNavigation(newState);
  };

  return (
    <Page title={t("backend.sidebar.navigation")}>
      <Card>
        <Card.Header>
          <h3>Menù di navigazione principale</h3>
          <Button handleClick={() => console.log(`Add new nav link`)}>
            {t("backend.button.addNew")}
          </Button>
        </Card.Header>
        {mainNavigation.length > 1 && (
          <main className={classes.main}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="mainNavigation">
                {(provided, snapshot) => (
                  <ul
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {mainNavigation.map((el, i) => (
                      <NavCard {...el} key={i} index={i} />
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </main>
        )}
      </Card>
    </Page>
  );
};

export default Navigation;
