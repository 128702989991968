import { Header, Footer } from "./";

const PublicLayout = ({ children }) => {
  return (
    <>
      <Header />
      <main>
        {children}
        <Footer />
      </main>
    </>
  );
};

export default PublicLayout;
