import axios from "axios";

const getList = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/pages/list`
  );
  if (data.success) return data.data;
};

const create = async (page) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/pages`,
    page
  );
  if (data.success) return data.data;
};

const getBySlug = async (slug) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/pages/slug/${slug}`
  );
  if (data.success) return data.data;
};

const api = {
  getList,
  getBySlug,
  create,
};

export default api;
