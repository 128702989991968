import { Route, Redirect } from "react-router-dom";
import { Fragment, useContext } from "react";
import { AdminLayout } from "components/layout";
import { AdminContext } from "contexts/admin.context";
import { CmsUserContext } from "contexts/cmsUserContext";
import {
  CollectionList,
  CollectionDetails,
  Dashboard,
  Settings,
  CreatePage,
  EditPage,
  Navigation,
  Analytics,
} from "screens";
import Users from "screens/admin/Users/Users";

const AdminRoutes = ({ collections, pages }) => {
  const { adminBasePath } = useContext(AdminContext);
  const { user } = useContext(CmsUserContext);

  let basePath = "/admin";
  if (adminBasePath) basePath = adminBasePath;

  if (!user) return <Redirect to={`${basePath}/login`} />;

  return (
    <AdminLayout>
      <Route exact path={`${basePath}`} component={Dashboard} />
      <Route exact path={`${basePath}/settings`} component={Settings} />
      <Route exact path={`${basePath}/navigation`} component={Navigation} />
      <Route exact path={`${basePath}/analytics`} component={Analytics} />
      <Route exact path={`${basePath}/users`} component={Users} />
      {collections.map((el) => (
        <Fragment key={`route-collection-${el.id}`}>
          <Route exact path={`${basePath}/collections/${el.slug}`}>
            <CollectionList collectionId={el.id} />
          </Route>
          <Route exact path={`${basePath}/collections/${el.slug}/edit/:id`}>
            <CollectionDetails collectionId={el.id} />
          </Route>
        </Fragment>
      ))}
      <Route exact path={`${basePath}/pages/create`} component={CreatePage} />
      {pages.map((page) => (
        <Route
          key={page.slug}
          exact
          path={`${basePath}/pages/edit/:slug`}
          component={EditPage}
        />
      ))}
    </AdminLayout>
  );
};

export default AdminRoutes;
