import PreviewItem from "./PreviewItem";
import classes from "./Preview.module.scss";

const Preview = (props) => {
  const {
    blocks,
    // MODAL METHODS
    openHeadingModal,
    openParagraphModal,
    openNavLinkModal,
    openExtLinkModal,
    openGridModal,
    openImgTextModal,
    openSplashImageModal,
    // FORM METHODS
    changeBlockOrder,
    setSelectedBlock,
    deleteBlock,
  } = props;

  return (
    <div className={classes.preview}>
      {blocks.map((block, index) => {
        let openModal = () => {};
        switch (block.blockType) {
          case "Heading":
            openModal = openHeadingModal;
            break;
          case "Paragraph":
            openModal = openParagraphModal;
            break;
          case "NavLink":
            openModal = openNavLinkModal;
            break;
          case "ExtLink":
            openModal = openExtLinkModal;
            break;
          case "Grid":
            openModal = openGridModal;
            break;
          case "SplashImage":
            openModal = openSplashImageModal;
            break;
          case "ImageText":
            openModal = openImgTextModal;
            break;
          default:
            break;
        }
        return (
          <PreviewItem
            key={index}
            block={block}
            index={index}
            classes={classes}
            openModal={openModal}
            totalBlocks={blocks.length}
            deleteBlock={deleteBlock}
            changeBlockOrder={changeBlockOrder}
            setSelectedBlock={setSelectedBlock}
          />
        );
      })}
    </div>
  );
};

export default Preview;
