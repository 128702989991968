import { useState } from "react";

const BlockNavLink = (props) => {
  const {
    block,
    allBlocks,
    handleBlock,
    openNavLinkModal,
    setSelectedBlock,
    classes,
  } = props;

  const selectedIndex = block ?? -1;
  const selectedBlock = block >= 0 ? allBlocks[block] : {};

  const [path, setPath] = useState(selectedBlock?.props?.path || "");
  const [label, setLabel] = useState(selectedBlock?.props?.columns || "");

  const handleSave = () => {
    handleBlock(selectedIndex, {
      blockType: "NavLink",
      props: {
        path,
        label,
      },
    });
    resetContent();
  };

  const resetContent = () => {
    setPath("");
    setLabel("");
    openNavLinkModal(false);
    setSelectedBlock(null);
  };

  return (
    <div className={classes.dialog}>
      <h3>Aggiungi collegamento a pagina interna</h3>
      <fieldset>
        <label htmlFor="path">Percorso URI pagina</label>
        <input
          type="text"
          name="path"
          onChange={(e) => setPath(e.target.value)}
        />
      </fieldset>
      <fieldset>
        <label htmlFor="label">Testo pulsante</label>
        <input
          type="text"
          name="label"
          onChange={(e) => setLabel(e.target.value)}
        />
      </fieldset>
      <div className={classes.dialogButtons}>
        <button onClick={resetContent}>Annulla</button>
        <button onClick={handleSave}>Salva</button>
      </div>
    </div>
  );
};

export default BlockNavLink;
