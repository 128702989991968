import classes from "./Filters.module.scss";

const Filters = (props) => {
  const { sort, handleLimitChange, handleSortChange } = props;

  return (
    <form onSubmit={() => {}} className={classes.filters}>
      <fieldset>
        <label>Elementi per pagina</label>
        <select onChange={handleLimitChange}>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={200}>200</option>
        </select>
      </fieldset>
      <fieldset>
        <label>Ordina per:</label>
        <select value={sort.key} onChange={handleSortChange}>
          <option value="id">ID</option>
          <option value="createdAt">Data di creazione</option>
        </select>
      </fieldset>
    </form>
  );
};

export default Filters;
