import { Link } from "react-router-dom";
import styles from "./Button.module.css";

const Button = ({ children, center, link, disabled = false }) => {
  if (link)
    return (
      <Link
        to={link}
        className={`${styles.button} ${center ? styles.alignCenter : ""}`}
      >
        {children}
      </Link>
    );
  return (
    <button
      className={`${styles.button} ${center ? styles.alignCenter : ""}`}
      disabled={disabled}
    >
      {disabled ? "Attendere prego..." : children}
    </button>
  );
};

export default Button;
