import Api from "api";
import { useState } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

const BlockImageText = (props) => {
  const {
    slug,
    block,
    classes,
    allBlocks,
    handleBlock,
    setSelectedBlock,
    openImgTextModal,
  } = props;

  const selectedIndex = block ?? -1;
  const selectedBlock = block >= 0 ? allBlocks[block] : {};

  const [text, setText] = useState(
    selectedBlock?.props?.text.length
      ? EditorState.createWithContent(stateFromHTML(selectedBlock?.props?.text))
      : EditorState.createEmpty()
  );
  const [image, setImage] = useState(selectedBlock?.props?.image || "");
  const [altText, setAltText] = useState(selectedBlock?.props?.altText || "");
  const [title, setTitle] = useState(selectedBlock?.props?.title || "");
  const [biggerText, setBiggerText] = useState(
    selectedBlock?.props?.biggerText || false
  );
  const [isReversed, setReversed] = useState(
    selectedBlock?.props?.isReversed || false
  );

  const uploadSingleImage = async (file) => {
    const form = new FormData();
    form.append("image", file);
    const res = await Api.media.upload(slug, form);
    setImage(res);
  };

  const onEditorStateChange = (editorState) => {
    setText(editorState);
  };

  const handleSave = () => {
    handleBlock(selectedIndex, {
      blockType: "ImageText",
      props: {
        text: stateToHTML(text.getCurrentContent()),
        title,
        image,
        altText,
        biggerText,
        isReversed,
      },
    });
    resetContent();
  };

  const resetContent = () => {
    setText("");
    setTitle("");
    setImage("");
    setAltText("");
    setReversed(false);
    setBiggerText(false);
    openImgTextModal(false);
    setSelectedBlock(null);
  };

  return (
    <div className={classes.dialog}>
      <h3>Aggiungi blocco immagine + testo</h3>
      <fieldset>
        <label htmlFor="altText">Titolo blocco testuale</label>
        <input
          type="text"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </fieldset>
      <fieldset>
        <label htmlFor="text">Contenuto testuale</label>

        <Editor
          editorState={text}
          onEditorStateChange={onEditorStateChange}
          wrapperClassName={classes.wrapper}
          editorClassName={classes.editor}
          toolbarClassName={classes.toolbar}
          toolbar={{
            options: ["inline", "list", "textAlign", "history", "link"],
            inline: { inDropdown: false },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
        />
      </fieldset>
      <fieldset>
        <label htmlFor="image">Immagine</label>
        <input
          type="file"
          name="image"
          onChange={(e) => uploadSingleImage(e.target.files[0])}
        />
      </fieldset>
      <fieldset>
        <label htmlFor="altText">ALT text dell'immagine</label>
        <input
          type="text"
          name="altText"
          value={altText}
          onChange={(e) => setAltText(e.target.value)}
        />
      </fieldset>
      <fieldset>
        <label htmlFor="biggerText">Colonna testo più grande?</label>
        <input
          type="checkbox"
          name="biggerText"
          checked={biggerText}
          onChange={(e) => setBiggerText(e.target.checked)}
        />
      </fieldset>
      <fieldset>
        <label htmlFor="isReversed">Inverti testo e immagine</label>
        <input
          type="checkbox"
          name="isReversed"
          checked={isReversed}
          onChange={(e) => setReversed(e.target.checked)}
        />
      </fieldset>
      <div className={classes.dialogButtons}>
        <button onClick={resetContent}>Annulla</button>
        <button onClick={handleSave}>Salva</button>
      </div>
    </div>
  );
};

export default BlockImageText;
