import Button from "components/cms/core/Button";
import { AdminContext } from "contexts/admin.context";
import { CmsUserContext } from "contexts/cmsUserContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import classes from "./Settings.module.scss";
import { Card } from "components/cms";
import { Page } from "components/cms/core";

const Settings = () => {
  const { t } = useTranslation();
  const { user } = useContext(CmsUserContext);
  const settings = useContext(AdminContext);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Page title={t(`backend.sidebar.settings`)}>
      <Card>
        <Card.Header>
          <h3>Impostazioni CMS</h3>
        </Card.Header>
        <form onSubmit={handleSubmit} className={classes.form}>
          {Object.keys(settings)
            .filter((el) => el !== "theme" && el !== "setTheme")
            .map((key) => (
              <fieldset key={`admin-control-panel-settings-${key}`}>
                <label htmlFor={key}>{t(`backend.settings.${key}`)}</label>
                <input
                  type="text"
                  aria-label={key}
                  value={settings[key]}
                  name={key}
                  disabled
                />
              </fieldset>
            ))}
          {user.role === "SUPERADMIN" && (
            <div className={classes.userActions}>
              <Button variant="primary">{t(`backend.core.save`)}</Button>
            </div>
          )}
        </form>
      </Card>
    </Page>
  );
};

export default Settings;
