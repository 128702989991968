import IconInfoCircle from "assets/icons/info-circle";
import styles from "./InfoTooltip.module.scss";

const InfoTooltip = (props) => {
  const { dialogBox } = props;

  return (
    <div className={styles.tooltip}>
      {/* TOOLTIP ICON */}
      <div className={styles.tooltipDialog}>
        <IconInfoCircle />
      </div>
      {/* TOOLTIP DIALOG */}
      <div className={styles.tooltipDialogBox}>{dialogBox}</div>
    </div>
  );
};

export default InfoTooltip;
