import { useState } from "react";

import { DialogBox } from "components/cms";
import { Container } from "components/core";
import classes from "./Grid.module.scss";

import GridItem from "./GridItem";

const Grid = (props) => {
  const { items = [], columns = 3, hasLightbox = true } = props;
  const [activeItem, setActiveItem] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeSrc, setActiveSrc] = useState(0);

  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 150) {
      swipeForward();
    }

    if (touchStart - touchEnd < -150) {
      swipeBackwards();
    }
  };

  const swipeBackwards = () => {
    if (activeItem === 0) setActiveItem(items.length - 1);
    else setActiveItem(activeItem - 1);
  };
  const swipeForward = () => {
    if (activeItem === items.length - 1) setActiveItem(0);
    else setActiveItem(activeItem + 1);
  };

  const moveBackwards = () => {
    if (activeSrc === 0) setActiveSrc(items.length - 1);
    else setActiveSrc(activeSrc - 1);
  };
  const moveForward = () => {
    if (activeSrc === items.length - 1) setActiveSrc(0);
    else setActiveSrc(activeSrc + 1);
  };

  return (
    <Container>
      <div
        initial="hidden"
        className={`${classes.grid} ${classes[`col-${columns}`]}`}
      >
        {items.map((item, index) => (
          <GridItem
            key={index}
            index={index}
            activeItem={activeItem}
            hasLightbox={hasLightbox}
            setActiveSrc={setActiveSrc}
            setModalOpen={setModalOpen}
            handleTouchStart={handleTouchStart}
            handleTouchMove={handleTouchMove}
            handleTouchEnd={handleTouchEnd}
            item={item}
          />
        ))}
      </div>
      <div className={classes.bullets}>
        {[...Array(items.length)].map((_, index) => (
          <span
            key={`grid-mobile-slider-${index}`}
            className={`${index === activeItem ? classes.active : ""}`}
            onClick={() => setActiveItem(index)}
          />
        ))}
      </div>
      {hasLightbox && isModalOpen && (
        <DialogBox closeDialog={() => setModalOpen(false)}>
          <div className={classes.dialog}>
            {items.length > 1 && <button onClick={moveBackwards}>‹</button>}
            <img
              src={`${process.env.REACT_APP_API_URL.replace(
                "/api",
                ""
              )}/${items[activeSrc].replace("media/", "")}`}
              alt=""
            />
            {items.length > 1 && <button onClick={moveForward}>›</button>}
          </div>
        </DialogBox>
      )}
    </Container>
  );
};

export default Grid;
