import Api from "api";
import { createContext, useEffect, useState } from "react";

export const CmsUserContext = createContext();

export const CmsUserProvider = ({ children }) => {
  const [user, setUser] = useState();

  useEffect(() => {
    const fetchUser = async () => {
      const user = await Api.admin.auth.getUserFromStorage();
      setUser(JSON.parse(user));
    };

    fetchUser();
  }, []);

  return (
    <CmsUserContext.Provider value={{ user, setUser }}>
      {children}
    </CmsUserContext.Provider>
  );
};
