import { useInView } from "react-intersection-observer";
import { useEffect, useRef, useState } from "react";
import { Expo } from "gsap/gsap-core";
import gsap from "gsap";

import classes from "./GridItem.module.scss";

const GridItem = ({
  index,
  activeItem,
  hasLightbox,
  setActiveSrc,
  setModalOpen,
  handleTouchStart,
  handleTouchMove,
  handleTouchEnd,
  item,
}) => {
  const img = useRef();
  const imgInnerContainer = useRef();
  const imgContainer = useRef();

  const { ref, inView } = useInView();
  const tl = useState(() => new gsap.timeline({ yoyo: false }));
  const [visible, setVisible] = useState(false);

  const animate = () => {
    tl[0]
      .from(imgContainer.current, 1.5, {
        height: 0,
        opacity: 0,
        ease: Expo.easeInOut,
      })
      .from(
        img.current,
        1.35,
        {
          opacity: 0,
          scale: 1.8,
          ease: Expo.easeOut,
        },
        1.45
      )
      .to(
        imgInnerContainer.current,
        1.25,
        {
          height: 0,
          opacity: 0.75,
          ease: Expo.easeInOut,
        },
        1.25
      );
  };

  useEffect(() => {
    if (inView) {
      if (window.innerWidth > 767)
        setTimeout(() => {
          if (!visible) animate();
          setVisible(true);
        }, `${(index / 2) * 500}`);
      else {
        setVisible(true);
      }
    }

    //eslint-disable-next-line
  }, [ref, inView]);

  return (
    <div
      ref={ref}
      className={`${classes.gridItem} 
      ${visible ? classes.visible : ""}
    ${index === activeItem ? classes.active : ""} 
    ${hasLightbox ? classes.clickable : ""}`}
      key={`custom-grid-item-${index}`}
      onClick={() => {
        setActiveSrc(index);
        setModalOpen(true);
      }}
      onTouchEnd={handleTouchEnd}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <img
        ref={img}
        src={`${process.env.REACT_APP_API_URL.replace(
          "/api",
          ""
        )}/${item.replace("media/", "")}`}
        alt=""
      />
      <div ref={imgContainer}>
        <div ref={imgInnerContainer}></div>
        <img
          ref={img}
          src={`${process.env.REACT_APP_API_URL.replace(
            "/api",
            ""
          )}/${item.replace("media/", "")}`}
          alt=""
        />
      </div>
    </div>
  );
};

export default GridItem;
