import { Fragment, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";

import Api from "api";
import { Container, Loader } from "components/core";
import { Paragraph } from "components/modules";
import { LanguageContext } from "contexts/languageContext";

import { Hero, RelatedEntries, VideoEmbed } from "./components";
import classes from "./JournalDetail.module.scss";

const JournalDetail = () => {
  const { slug } = useParams();
  const { lang } = useContext(LanguageContext);

  const [post, setPost] = useState({
    id: 0,
    label: "",
    slug: "",
  });
  const [content, setContent] = useState();
  const [related, setRelated] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const post = await Api.entities.getBySlug(slug);
      const related = await Api.entities.getInCollection(2, {
        limit: 3,
        page: 0,
      });
      setPost(post);
      setRelated(related.items);
      setLoading(false);
    };

    init();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchTranslation = async () => {
      const content = await Api.translations.getEntityTranslation(
        post.id,
        lang
      );
      setContent(content);
    };

    if (post.id) fetchTranslation();
  }, [post.id, lang]);

  if (isLoading)
    return (
      <Container>
        <Loader />
      </Container>
    );

  if (!content) return null;

  return (
    <Fragment>
      {/* BLOG POST DETAILS */}
      <Container small>
        <Hero
          title={content.title}
          publishedAt={content.publishedAt}
          thumbnail={
            content?.thumbnail
              ? `${process.env.REACT_APP_API_URL.replace(
                  "/api",
                  ""
                )}/${content?.thumbnail.replace("media/", "")}`
              : "https://via.placeholder.com/1920x1080"
          }
        />
        {content.description && <Paragraph content={content.description} />}
        {content.video && (
          <VideoEmbed video={content.video} label={post.label} />
        )}
      </Container>
      {/* RELATED ENTRIES */}
      <Container>
        <div className={classes.relatedEntries}>
          {related.map((item, index) => (
            <RelatedEntries item={item} key={index} />
          ))}
        </div>
      </Container>
    </Fragment>
  );
};

export default JournalDetail;
